import React, { SyntheticEvent } from 'react';
import Tile, {TileModel} from './components/tile';
import Color from '../tools/color';

interface Parameters { }
interface Props { params?: Parameters; } 
interface State { gridSize: {x: number, y: number}, baseColor: Color, tiles: Map<string, TileModel>}

class Board extends React.Component <Props, State> {

    constructor(props: Props){
        super(props);

        this.state = {gridSize: {x: 10, y: 10}, baseColor: Color.fromHex("FFF"), tiles: new Map()}
        this.generateTiles()

        this.state.tiles.get("4|4")?.togglePath(["u", "l", "l", "d", "d", "d", "r","r","r","r","u","u","u","u","u","l","l","l","l","l","l","d","d","d","d","d","d","d","r","r","r","r","r","r","r","r","u","u","u","u","u","u","u"],
            500)
    }

    generateTiles () {
        for (let x = 0; x < this.state.gridSize.x; x++) {
            for (let y = 0; y < this.state.gridSize.y; y++) {
                this.state.tiles.set(`${x}|${y}`, 
                    TileModel.fromObject({position: {x: x, y: y}, 
                    boardSize: this.state.gridSize, 
                    fillColor: this.state.baseColor,
                    onClick: (e: SyntheticEvent) => {this.tileClicked(e, `${x}|${y}`)}}))
            }
        }

        this.connectTiles()
    }

    connectTiles () {
        let xMax = this.state.gridSize.x
        let yMax = this.state.gridSize.y
        let tiles = this.state.tiles

        for (const tile of tiles.values()) {
            let up = tile.position.y - 1 >= 0 ? this.state.tiles.get(`${tile.position.x}|${tile.position.y - 1}`) : undefined
            let right = tile.position.x + 1 < xMax ? this.state.tiles.get(`${tile.position.x + 1 }|${tile.position.y}`) : undefined
            let down = tile.position.y + 1 < yMax ? this.state.tiles.get(`${tile.position.x}|${tile.position.y + 1}`) : undefined
            let left = tile.position.x - 1 >= 0 ? this.state.tiles.get(`${tile.position.x -1 }|${tile.position.y}`) : undefined
            tile.neighbors = [up, right, down, left]
        }
    }

    tileClicked (e: SyntheticEvent, tileIndex: string) {
        let tile = this.state.tiles.get(tileIndex)

        if (tile)
            tile.invertFillColor()
    }

    render(){
        let tiles: JSX.Element[] = []

        for (let x = 0; x < this.state.gridSize.x; x++) {
            for (let y = 0; y < this.state.gridSize.y; y++) {
                tiles.push(<Tile key={`${x}|${y}`} model={this.state.tiles.get(`${x}|${y}`)}></Tile>)
            }
        }

        return  <>
            <div style={{width: `500px`, height: `500px`, display: "flex", flexWrap: "wrap", flexDirection: "column", marginBottom: "100px"}}>
                {tiles}
            </div>
        </>
    }
}


export default (Board);