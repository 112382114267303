
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { withRouterParams } from './inc/helpers';
import Home from './pages/home';
import Login from './pages/login';
import UserRouter from './pages/users/router';
import AppointmentRouter from './pages/appointments/router';
import AttendanceRouter from './pages/attendance/router';
import AMPLayout from './layout/amplayout';

/* Spectra's Testjunk imports */
import Board from './test/gameBoard/board';
import TestForm from './test/formTest/form';
import SplitView from './pages/splitview';

interface Parameters { }
interface Props { params?: Parameters; }
interface State { }

class App extends React.Component <Props, State> {

    // updateTitle(): void { // was just testing to see if document.title could be updated from here
    //   document.title = "React App - " + new Date().toLocaleTimeString();
    //   setTimeout(() => { this.updateTitle() }, 1000);
    // }

    render(){

        // entire app is Layout (with navigation) and a child component(s) based on the route
        return <React.StrictMode>
          <BrowserRouter>
            <AMPLayout>
                <Routes>

                  {/* every page redirects here if there is no token */}
                  <Route path="/login" element={<Login />}> </Route>

                  {/* homepage/dashboard  */}
                  <Route path="/" element={<Home />}> </Route>

                  {/* User module -> sub router example */}
                  <Route path="/users/*" element={<UserRouter />}> </Route>

                  {/* Appointments module -> sub router example */}
                  <Route path="/appointments/*" element={<AppointmentRouter />}> </Route>

                  {/* Attendance module -> sub router example */}
                  <Route path="/attendance/*" element={<AttendanceRouter />}> </Route>

                  {/* Spectra's Testjunk */}
                  <Route path="/testjunk" element={<Board />}> </Route>
                  <Route path="/testjunk2" element={<TestForm />}> </Route>
                  <Route path="/test" element={<SplitView />}> </Route>


                  {/* 404 */}
                  <Route path="*" element={<><h1>404</h1></>}> </Route>

                </Routes>
            </AMPLayout>
          </BrowserRouter>
        </React.StrictMode>

    }
}


export default withRouterParams(App);
