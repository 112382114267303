export class Timestamp {
    totalMinutes

    constructor (time:number|string)
    {
        switch (typeof(time)) {
            case "number":
                if (time > 1440 || time < 0)
                    throw new Error ("Minute value out of timestamp range")
                this.totalMinutes = time
                break;
            case "string":
                this.totalMinutes = Timestamp.timestampToMinutes(time)
                break;
            default:
                this.totalMinutes = NaN
                break;
        }
    }

    get timestamp() {
        if (!Number.isNaN(this.totalMinutes) && this.totalMinutes !== undefined)
            return Timestamp.minutesToTimestamp(this.totalMinutes)
        else
            return undefined
    }

    get minutes () {
        if (!Number.isNaN(this.totalMinutes) && this.totalMinutes !== undefined)
            return this.totalMinutes % 60
        else
            return undefined
    }

    get hours () {
        if (!Number.isNaN(this.totalMinutes) && this.totalMinutes !== undefined)
            return Math.floor(this.totalMinutes / 60)
        else
            return undefined
    }

    isSet () {
        return this.totalMinutes && this.totalMinutes > 0 && this.totalMinutes < 1440
    }
    
    static minutesToTimestamp (minutes: number) {
        return `${`${Math.floor(minutes / 60)}`.padStart(2, "0")}:${`${minutes % 60}`.padStart(2, "0")}`
    }
    
    static timestampToMinutes (timestamp: string) {
        let timespan = timestamp.split(":")
        let hours = Number.parseInt(timespan[0])
        let minutes = Number.parseInt(timespan[1])

        if (Number.isNaN(hours) || Number.isNaN(minutes) ||
            hours < 0 || hours > 23 ||
            minutes < 0 || minutes > 59)
            throw new Error ("Invalid timespan")

        return hours*60+minutes
    }

    static timeOfDate (date: Date) {
        return new Timestamp (date.getMinutes() + date.getHours()*60)
    }
}