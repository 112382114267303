
import React from 'react';
import {  Routes, Route } from 'react-router-dom';
import { withRouterParams } from '../../inc/helpers';
import WeekView from './weekView';


interface Parameters { }
interface Props { params?: Parameters; }
interface State { }

class AttendanceRouter extends React.Component <Props, State> {

    render(){
        return  <>
          <Routes>
            {/* <Route path="/" element={<AttendanceOverview />}> </Route> */}
            <Route path="/week" element={<WeekView />}> </Route>
            <Route path="/week/:userType" element={<WeekView />}> </Route>
          </Routes>
        </>
    }
}


export default withRouterParams(AttendanceRouter);
