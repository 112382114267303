import React from "react";
import Color from "../../tools/color"
import Vector, { iVector } from "../../tools/vector"

export class TileModel {
    position: Vector
    boardSize: Vector
    fillColor: Color
    content: string 
    background: string 
    neighbors: (TileModel|undefined)[]
    onClick: Function

    component?: React.Component    

    constructor (
            position: Vector,
            boardSize: Vector,
            fillColor: Color,
            content: string = "",
            background: string = "",
            neighbors: TileModel[] = [],
            onClick: Function = ()=>{}) {
        this.position = position
        this.boardSize = boardSize
        this.fillColor = fillColor

        this.content = content
        this.background = background
        this.neighbors = neighbors
        this.onClick = onClick

        this.validate()
    }

    static fromObject (obj: {position: iVector
        boardSize: iVector
        fillColor: Color
        content?: string 
        background?: string 
        neighbors?: TileModel[] 
        onClick?: Function}) {

        let tm = new TileModel(
            Vector.fromInterface(obj.position),
            Vector.fromInterface(obj.boardSize),
            obj.fillColor
        )
        tm.content = obj.content || tm.content
        tm.neighbors = obj.neighbors || tm.neighbors
        tm.background = obj.background || tm.background
        tm.onClick = obj.onClick || tm.onClick

        return tm
    }

    validate () {
        if (!(this.position.x >= 0 && this.position.y >= 0))
            throw new Error("Invalid position provided")

        if (!(this.boardSize.x >= 0 && this.boardSize.y >= 0))
            throw new Error("Invalid board size provided")
    
        if (!this.fillColor)
            throw new Error("Invalid color provided")
    }

    invertFillColor () {
        this.fillColor = this.fillColor.inverted
        if (this.component)
            this.component.setState({})
    }

    togglePath (path: ("u"|"d"|"l"|"r")[], delay?:number) {
        this.invertFillColor()

        let passOn = () => {
            if (path.length > 0)
            {
                switch (path.shift()) {
                    case "u":
                        this.neighbors[0]?.togglePath(path, delay)
                        break;
                    case "r":
                        this.neighbors[1]?.togglePath(path, delay)
                        break;
                    case "d":
                        this.neighbors[2]?.togglePath(path, delay)
                        break;
                    case "l":
                        this.neighbors[3]?.togglePath(path, delay)
                        break;
                    default:
                        throw Error("Invalid path instruction")
                }
            } 
        }

        if (delay)
            setTimeout(() => {
                passOn()       
            }, delay);
        else
            passOn()
    }

    render(){
        let css : React.CSSProperties   = {}

        css.width = `${100/this.boardSize.x}%`
        css.height = `${100/this.boardSize.y}%`
        css.backgroundColor = this.fillColor.asCssHex
        css.borderStyle = "solid"
        css.borderWidth = "1px"
        css.borderColor = this.fillColor.blackWhiteContrast.asCssHex
        css.boxSizing = "border-box"

        return  <>
            <div onClick={(e) => {this?.onClick && this.onClick(e);}} style={css}>
                {this?.content || ""}
            </div>
        </>
    }
}


interface Props { model?: TileModel; } 
interface State {}

class Tile extends React.Component <Props, State> {

    constructor(props: Props){
        super(props);
        
        if (this.props.model)
            this.props.model.component = this
    }

    render(){
        return this.props.model?.render() || <></>
    }
}

export default Tile