
import React from 'react';
import {  Routes, Route } from 'react-router-dom';
import UserOverview from './overview';
import { withRouterParams } from '../../inc/helpers';
import UserForm from './form';


interface Parameters { }
interface Props { params?: Parameters; }
interface State { }

class UserRouter extends React.Component <Props, State> {

    render(){
        return  <>
          <Routes>
            <Route path="/add" element={<UserForm />}> </Route>
            <Route path="/edit/:id" element={<UserForm />}> </Route>

            <Route path="/" element={<UserOverview />}> </Route>
            <Route path="/:userType" element={<UserOverview />}> </Route>
          </Routes>
        </>
    }
}


export default withRouterParams(UserRouter);
