
import React from 'react';
import { Badge, Button, Card, Col, Container, Dropdown, ListGroup, Offcanvas, Row, Image, Form } from 'react-bootstrap';
import './amplayout.scss';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Menutabs from './menutabs';
import APIClient from '../inc/APIClient';
import { tabChange } from '../inc/helpers';

interface Props {
    children: React.ReactNode;
}

type Placement = 'start' | 'end' | 'top' | 'bottom'

interface State {
  navExpanded: boolean;
  userDropdown: boolean;
  offcanvas: boolean;
  placement: Placement;
  activeGroup?: number;
}

let api = new APIClient();

class AMPLayout extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = { offcanvas: false, placement: 'bottom', navExpanded: api.user ? true : false, userDropdown: false, activeGroup: api.activeGroup };

    this.toggleOffCanvas = this.toggleOffCanvas.bind(this);
    this.toggleUserDropdown = this.toggleUserDropdown.bind(this);
    this.toggleNavExpanded = this.toggleNavExpanded.bind(this);
    this.offCanvasPlacement = this.offCanvasPlacement.bind(this);

  }

  toggleOffCanvas = () => { this.setState({offcanvas: !this.state.offcanvas}); }
  toggleUserDropdown = () => { this.setState({userDropdown: !this.state.userDropdown}); }
  toggleNavExpanded = () => { this.setState({navExpanded: !this.state.navExpanded}); }
  offCanvasPlacement = (placement: Placement) => {  this.setState({placement: placement }); }

  render() {


    // get active group name from api.user.groups
    let activeName = api.user?.groups?.map((group:any) => { if (group.id === this.state.activeGroup) { return group.name; } })
    let activeRole = api.user?.groups?.map((group:any) => { if (group.id === this.state.activeGroup) { return group.role; } })

    return (
      <div className='wrap'>

        <div className='which-res'></div>

        <Container fluid>
        <Row className='top' style={{display: api.user ? "" : "none"}}>
          <Col>
                    <FontAwesomeIcon className='' icon="bars" onClick={this.toggleNavExpanded} />&nbsp;
          </Col>
          <Col>

          { api.user && api.user.groups && <div className='search'>
            <div className="search-icon" >
                <FontAwesomeIcon icon="search" />
            </div>
            <Form.Control type="text" placeholder="Search" className="search-bar" />
          </div> }
          </Col>
          <Col>

          { api.user && api.user.groups && <>
            <div className='user-badge'>
                <span className='float-end'>
                  <FontAwesomeIcon icon="calendar-days" />
                  <span className="position-absolute top-20 p-1 start-30 bg-primary rounded-circle">  </span>

                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <FontAwesomeIcon icon="bell" />
                  <span className="position-absolute top-20 p-1 start-30 bg-danger rounded-circle">  </span>

                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <Image onClick={this.toggleUserDropdown} src="http://cdn.playbuzz.com/cdn/5240d5a2-1d1c-46e4-bad4-a983b4860516/81ea3784-b76e-49e2-ab0f-1b3c304b6977.jpg" roundedCircle className='profile-image'/>
                </span>
                <Dropdown show={this.state.userDropdown} className="user-dropdown">
                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1"><FontAwesomeIcon icon="check" /> Autimatisering</Dropdown.Item>
                    <Dropdown.Item href="#/action-2"><FontAwesomeIcon icon="check" /> Switch jeugdzorg</Dropdown.Item>
                    <Dropdown.Item href="#/action-3"><FontAwesomeIcon icon="check" /> Kentalis</Dropdown.Item>

                    <Dropdown.Divider />
                    <Dropdown.Item href={`/users/edit/${api.user.id}`}><FontAwesomeIcon icon="user" />&nbsp; Mijn Profiel</Dropdown.Item>
                    <Dropdown.Item href={`/appointments/user/${api.user.id}`}><FontAwesomeIcon icon="calendar-days" />&nbsp; Mijn Agenda</Dropdown.Item>
                    <Dropdown.Item href="#/action-3"><FontAwesomeIcon icon="gear" />&nbsp; Instellingen</Dropdown.Item>


                    <Dropdown.Divider />
                    <Dropdown.Item href="#/action-2" onClick={(e: any) => { e.preventDefault(); alert("lol") }}><FontAwesomeIcon icon="info" />&nbsp; Hulp nodig?</Dropdown.Item>

                    <Button variant='primary' size="sm" style={{margin:"4px"}} onClick={(e) => { tabChange("users"); } } >
                      <FontAwesomeIcon icon="wrench" />&nbsp;  Modules
                    </Button>

                    <Dropdown.Divider />

                    { api.user && api.user.groups && <>
                        <Badge bg="dark">{activeName} ({activeRole})</Badge><br/>


                        <Form.Select as="div" placeholder="Selecteer een groep" value={this.state.activeGroup}
                          onChange={(e:any) => { api.setActiveGroup(e.target.value); this.setState({ activeGroup: parseInt(e.target.value)})  }} >
                          { api.user && api.user.groups && api.user.groups?.map((group:any) => {
                            return <option key={group.id} value={group.id}>{group.name} ({group.role})</option>
                          })}
                        </Form.Select>
                      </>}

                  </Dropdown.Menu>
                </Dropdown>
            </div>
            </>}
          </Col>
        </Row>


        {/* can be toggled using this.toggleOffCanvas() */}
        <Offcanvas show={this.state.offcanvas} onHide={this.toggleOffCanvas} placement={this.state.placement}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Offcanvas</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            Some text as placeholder. <br/>
            <h6>Move me</h6>
            <Button variant='info' onClick={() => {this.offCanvasPlacement("bottom")}}>bottom</Button> &nbsp;
            <Button variant='info' onClick={() => {this.offCanvasPlacement("top")}}>top</Button> &nbsp;
            <Button variant='info' onClick={() => {this.offCanvasPlacement("start")}}>start</Button> &nbsp;
            <Button variant='info' onClick={() => {this.offCanvasPlacement("end")}}>end</Button> &nbsp;
          </Offcanvas.Body>
        </Offcanvas>

        <Row className='middle'>
          <div className="d-flex" style={{paddingRight: "0px"}}>
            <div className="navigation justify-content-start" style={{ 
              minWidth : !this.state.navExpanded ? "" : "300px",}}>
              { api.user && <Menutabs show={this.state.navExpanded} cb={this.toggleNavExpanded}  />  }
            </div>
            <div className='ms-2 content justify-content-end' style={{
              // These style settings try to ensure the established UI doesn't change, but allow for a vertical scrollbar within the container
              width: "100%", 
              overflowY: "auto", 
              overflowX: "hidden", 
              paddingRight: "calc(var(--bs-gutter-x) * .5)",
              maxHeight: "calc(100vh - 40px)"
            }}>
              { this.props.children }
            </div>
          </div>
        </Row>

        </Container>
      </div>
    );
  }
}


export default AMPLayout;
