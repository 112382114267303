export default class Color {
    R:number = 0
    G:number = 0
    B:number = 0
    A:number = 255

    static fromRGBA (R : number, G : number, B : number, A? : number) {
        let color = new Color()
        color.R = R 
        color.G = G
        color.B = B
        color.A = A ? A : 255
        if (!color.validate())
            throw new Error("Invalid color code provided")
        return color
    }

    static fromHex (hex : string) {
        if (hex[0] === "#")
            hex = hex.slice(1)
    
        let stepSize = Math.floor(hex.length / 3)
        let step = 0

        let color = new Color()
        color.R = Number(`0x${"".padStart(2, hex.substring(step, step += stepSize))}` )
        color.G = Number(`0x${"".padStart(2, hex.substring(step, step += stepSize))}` )
        color.B = Number(`0x${"".padStart(2, hex.substring(step, step += stepSize))}` )
        color.A = hex.length > stepSize * 3 ? Number(`0x${"".padStart(2, hex.substring(step, step += stepSize))}` ) : 255
        
        if (!color.validate())
            throw new Error("Invalid color code provided")
        return color
    }

    validate () {
        if (this.R > 255 || this.R < 0 || Number.isNaN(this.R))
            return false
        if (this.G > 255 || this.G < 0 || Number.isNaN(this.G))
            return false
        if (this.B > 255 || this.B < 0 || Number.isNaN(this.B))
            return false
        if (this.A > 255 || this.A < 0 || Number.isNaN(this.A))
            return false
        return true
    }

    get asCssHex () {
        let R = this.R.toString(16).padStart(2,"0")
        let G = this.G.toString(16).padStart(2,"0")
        let B = this.B.toString(16).padStart(2,"0")
        let A = this.A.toString(16).padStart(2,"0")
        return `#${R}${G}${B}${A}`
    }

    get asCssRGBA () {
        let A = this.A / 255
        return `rgba(${this.R},${this.G},${this.B},${A})`
    }

    get blackWhiteContrast () {
        return (this.R + this.B + this.G) / 3 > 128 ? Color.fromRGBA(0,0,0) : Color.fromRGBA(255,255,255)
    }

    get inverted () {
        return Color.fromRGBA(Math.abs(255 - this.R), Math.abs(255 - this.G), Math.abs(255 - this.B), this.A)
    }
}