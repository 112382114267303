import * as yup from 'yup';
import { capitalize } from '../../inc/helpers';

export class ClTableDefinition {
  name: string
  columns: Map<string, ClColumn>
  
  constructor (iClTableDefinition: IClTableDefinition) {
    this.name = iClTableDefinition.name
    this.columns = new Map(iClTableDefinition.columns.map((i)=>{return [i.name, new ClColumn(i)]}))
  }

  /**
   * Validate a row to see if it comforms to the table definition
   * @param row The row to be checked, it should be an object formatted as ***\{\<column name\>: \<value\>\}***
   * @param catchErrors By default all validation errors will be thrown, if catchErrors is set to true, errors will instead be caught and returned as an object
   * @returns undefined, or the list of errors formatted as ***\{\<column name\>: \<error message\>\}***
   */
  validateRow (row: any, catchErrors: boolean = false) {
    let errors: any = {}

    let validateField = (col: ClColumn, val : any)=>{
      // Yup validation
      col.yupSchema.validateSync(val, {strict: true})

      // Restrictions validation
      let res = col.restrictions
      if (res && val) {
        if (res.max !== undefined && val > res.max.value) {
          throw new Error(res.max.message || `${capitalize(col.name)} is not allowed to be greater than ${res.max.value}`)
        }
        if (res.min !== undefined && val < res.min.value) {
          throw new Error(res.min.message || `${capitalize(col.name)} is not allowed to be smaller than ${res.max?.value}`)
        }
        if (res.lessThanCol && val > row[res.lessThanCol.column] + res.lessThanCol.offset) {
          throw new Error(res.lessThanCol.message || `${capitalize(col.name)} is not allowed to be greater than ${res.lessThanCol.column} ${res.lessThanCol.offset ? `+ ${res.lessThanCol.offset}` : ""}`)
        }
        if (res.moreThanCol && val < row[res.moreThanCol.column] + res.moreThanCol.offset) {
          throw new Error(res.moreThanCol.message || `${capitalize(col.name)} is not allowed to be greater than ${res.moreThanCol.column} ${res.moreThanCol.offset ? `+ ${res.moreThanCol.offset}` : ""}`)
        }
        if (res.maxLength && (val as string).length > res.maxLength.value ) {
          throw new Error(res.maxLength.message || `${capitalize(col.name)} is not allowed to be longer than ${res.maxLength.value} characters`)
        }
        if (res.minLength && (val as string).length < res.minLength.value ) {
          throw new Error(res.minLength.message || `${capitalize(col.name)} is not allowed to be shorter than ${res.minLength.value} characters`)
        }
        if (res.exactLength && (val as string).length !== res.exactLength.value ) {
          throw new Error(res.exactLength.message || `${capitalize(col.name)} must be exactly ${res.minLength?.value} characters long`)
        }
      }

      // Implicit validation
      if (val){
        switch (col.type) {
          case "string":
          case "number":
          case "date":
            // no additional validation needed
            break;
          case "select":
            if (!col.selectOptions?.includes(val))
              throw new Error (`select column does not contain provided value option: column = ${col.name}, value = ${val}`)
            break;
        }
      }
    }

    for (const col of this.columns.values()) {
      let val = row[col.name]

      try {
        validateField(col, val)
      } catch (error: any) {
        if (catchErrors)
          errors[col.name] = error.message as string
        else
          throw error
      }
    }
    return Object.keys(errors).length > 0 ? errors : undefined    
  }
}

export interface IClTableDefinition {
  name: string
  columns: IClColumn[]
}

/** Class for defining a column within a ClTableDefinition */
export class ClColumn {
  /** Name of the column */
  name: string
  /** Input type of the column (not to be confused with data type) */
  type: "number"|"string"|"date"|"select"
  /** Options that will appear in the column input when the column is a of the type "select". */
  selectOptions?: string []
  /** Custom yup schema for validation. If no schema is provided, a schema will be naturally inferred through the "type", "required" and "restrictions" fields. */
  yupSchema: yup.Schema
  /** Placeholder for the column, if no placeholder is provided, the placeholder will be the name of the column */
  placeholder: string
  /**
   * Additional restrictions that can be imposed onto a column, these are used for visual formatting when applicable and will also be used for validation outside of yup.  
   * All restrictions are applicable to specific input types and will be shown. If applied incorrectly the restriction will be ignored.
   */
  restrictions?: restrictions

  /** The table this column belongs to */
  table?: ClTableDefinition

  /**
   * Create a new instance of a ClColumn for defining a column within a CrudList component instance
   * @param iClColumn 
   */
  constructor (
    iClColumn: IClColumn
  ) {
    this.name = iClColumn.name
    this.placeholder = iClColumn.placeholder || this.name
    this.validateCol(iClColumn)
    this.type = iClColumn.type
    this.selectOptions = iClColumn.selectOptions
    this.restrictions = iClColumn.restrictions
    if (iClColumn.yupSchema){
      this.yupSchema = iClColumn.yupSchema
    } else {
      this.yupSchema = this.inferYup()
    }
  }

  /**
   * Try to parse and convert if needed a value that would fit inside of this column. 
   * @param value value of unknown data type
   * @returns value as the datatype that befits this column, or else returns an error
   */
  tryParseValue (value: any)
  {
    try {
      switch (this.type) {
        case "string": 
        case "select":
          return typeof value == "string" ? value : value.toString()
        case "number": 
          return typeof value == "number" ? value : Number.parseFloat(value)
        case "date":
          return typeof value == "object" && value instanceof Date ? value : new Date(value)
      }
    } catch (e) {
      return e
    }
  }

  /**
   * Try to infer yup based on column input type
   * @returns A yup schema befitting this column
   */
  inferYup() {
    let yupSchema
    switch (this.type) {
      case "string": 
        yupSchema = yup.string().typeError(this.restrictions?.typeMatch || "Invalid text provided")
        break;
      case "number": 
        yupSchema = yup.number().typeError(this.restrictions?.typeMatch || "Invalid number provided")
        break;
      case "date": 
        yupSchema = yup.date().typeError(this.restrictions?.typeMatch || "Invalid Date provided")
        break;
      case "select": 
        if (!(this.selectOptions && this.selectOptions.length > 0))
          throw new Error ("Invalid select options provided")
        yupSchema = yup.string().oneOf(this.selectOptions, "Invalid selection").typeError(this.restrictions?.typeMatch || "Invalid text provided")  
        break;
      default:
        throw new Error(this.restrictions?.typeMatch || "Unsupported datatype")
    }

    yupSchema = this.restrictions?.required ? yupSchema.required(
      (this.restrictions.required as any).message ? (this.restrictions.required as any).message : undefined
    ) : yupSchema

    return yupSchema
  }

  /**
   * Validate that the column is correctly constructed by checking if the right yup Datatype is set (if set) and that there are select options available when making a select column
   * @param i Interface that can be used to make a ClColumn object
   */
  validateCol (i: IClColumn) {

    switch (i.type) {
      case "string": 
        if (i.yupSchema && !(i.yupSchema instanceof yup.StringSchema))
          throw new Error ("Wrong type of yup schema provided for the datatype")
        break;
      case "number": 
        if (i.yupSchema && !(i.yupSchema instanceof yup.NumberSchema))
          throw new Error ("Wrong type of yup schema provided for the datatype")
        break;
      case "date": 
        if (i.yupSchema && !(i.yupSchema instanceof yup.DateSchema))
          throw new Error ("Wrong type of yup schema provided for the datatype")
        break;
      case "select": 
        if (!(i.selectOptions && i.selectOptions.length > 0))
          throw new Error("Invalid select options provided")
        break;
    }
  }
}

/** Interface used for the creation of a Crud List Column */
export interface IClColumn {
  /** Name of the column */
  name: string
  /** Input type of the column (not to be confused with data type) */
  type: "number"|"string"|"date"|"select"
  /** Options that will appear in the column input when the column is a of the type "select". */
  selectOptions?: string []
  /** Custom yup schema for validation. If no schema is provided, a schema will be naturally inferred through the "type", "required" and "restrictions" fields. */
  yupSchema?: yup.Schema
  /** Placeholder for the column, if no placeholder is provided, the placeholder will be the name of the column */
  placeholder?: string
  /**
   * Additional restrictions that can be imposed onto a column, these are used for visual formatting when applicable and will also be used for validation outside of yup.  
   * All restrictions are applicable to specific input types and will be shown. If applied incorrectly the restriction will be ignored.
   */
  restrictions?: restrictions
}

/**
 * Additional restrictions that can be imposed onto a column, these are used for visual formatting when applicable and will also be used for validation outside of yup.  
 *   
 * *note: All restrictions are applicable to specific input types which is documented per restriction. If applied incorrectly the restriction will be ignored.*  
 * *note: Some restrictions are mutually exclusive and will throw an error if applied simultaneously*
 */
interface restrictions {
  /** Column must have this value or greater, applicable for "number" and "date" */
  min?: {value: number, message?: string}
  /** Column must have this value or smaller, applicable for "number" and "date" */
  max?: {value: number, message?: string}
  /** Column must have a value smaller than the column referenced, applicable for "number" and "date"  */
  lessThanCol? : {column: string, offset: number, message?: string}
  /** Column must have a value greater than the column referenced, applicable for "number" and "date"  */
  moreThanCol? : {column: string, offset: number, message?: string}
  /** Column must be of this length or longer, applicable for "string" */
  minLength?: {value: number, message?: string}
  /** Column must be of this length or shorter, applicable for "string" */
  maxLength?: {value: number, message?: string}
  /** Column must be exactly of this length, applicable for "string" */
  exactLength?: {value: number, message?: string}
  /** Determines if the column is required, applicable to all*/
  required?: {message?: string} | boolean
  /** Type is always validated, but this allows for a custom message */
  typeMatch?: string
}