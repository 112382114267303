/*
Note: 
Formik would be applicable here as this component effectively contains a form.
However, the way this component is build up has the form part positioned inside of a table for purpose of having everything neatly lined up.
Forms cannot be used inside of a table, without the entire form being pushed into a single <td>.
Therefore the choice was made to not use Formik, and instead manually recreate the required functionalities.
*/

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { createRef } from 'react';
import { Button, Card, Form, Table } from 'react-bootstrap';
import { capitalize } from '../../inc/helpers';
import { ClColumn, ClTableDefinition, IClTableDefinition } from './CrudListModels';

interface Props {
  tableDefinition: IClTableDefinition
  values: any[]
  skipValidation?: boolean
}

interface State  {
  valueRows: any[]
  tableDefinition: ClTableDefinition
}

const customStyles = {
  editCell: {backgroundColor: "#D9D9D9", verticalAlign: "top"} as React.CSSProperties,
  headerCell: {padding: "calc(.375rem) calc(.75rem + 8px)"} as React.CSSProperties,
  valueCell: {padding: "calc(.375rem) calc(.75rem + 10px)", verticalAlign: "middle"} as React.CSSProperties,
}

export class CrudList extends React.Component <Props, State> {
  inputRow: React.RefObject<HTMLTableRowElement>

  constructor (props: Props) {
    super (props)

    this.state = {valueRows: props.values, tableDefinition: new ClTableDefinition(props.tableDefinition)}

    if (!props.skipValidation)
      this.validateValues(props.values)

    this.inputRow = createRef()
  }

  /**
   * Validate to see if the provided values are valid given the provided columns
   * @param props props provided to the list
   */
  validateValues (values : any[], catchErrors: boolean = false) {
    let errors: any = {}

    for (const value of values) {
      errors = this.state.tableDefinition.validateRow(value, catchErrors)
    }

    return errors && Object.keys(errors).length > 0 ? errors : undefined
  }

  /**
   * Generate a new row for the list
   */
  addRow() {
    let inputs = [...(this.inputRow.current?.querySelectorAll("td:not(:last-of-type) > div > *:first-child, th:not(:last-of-type) > div > *:first-child")||[])] as HTMLElement[]
    let feedbacks = [...(this.inputRow.current?.querySelectorAll("td:not(:last-of-type) > div > *:last-child, th:not(:last-of-type) > div > *:last-child")||[])] as HTMLElement[]
    let values = inputs.map((i:any)=>{return i.value})
    let columns = [...this.state.tableDefinition.columns.keys()]
    let newRow : any = {}
    for (let i = 0; i < columns.length; i++) {
      newRow[columns[i]] = this.state.tableDefinition.columns.get(columns[i])?.tryParseValue(values[i]);
    }
    
    let errors = this.validateValues([newRow], true)

    if (errors){
      console.log(errors)
      for (const [i, column] of columns.entries()) {
        if (errors[column]) {
          console.log("errors!")
          inputs[i].classList.add("is-invalid")
          feedbacks[i].innerText = capitalize(errors[column])
        } else {
          console.log("no errors!")
          inputs[i].classList.remove("is-invalid")
        }
      }
    }
    else{
      for (const [i] of columns.entries()) {
        inputs[i].classList.remove("is-invalid")
      }
      this.setState({valueRows: [...this.state.valueRows, newRow]})
    }
  }

  /**
   * Remove a row from the list based on the index
   * @param index 
   */
  removeRow(index: number) {
    this.state.valueRows.splice(index,1)
    this.setState({valueRows: this.state.valueRows})
  }

  /**
   * Generate a row that can edit a row
   * @returns jsx for the purpose of editing a row
   */
  generateEditRow() {
    let editRow = []

    let createInput = (col:ClColumn) => {
      let switchColType = (col:ClColumn) => {
        switch (col.type) {
          case "string":
            return <Form.Control type='text' maxLength={col.restrictions?.maxLength?.value} data-col={col.name} placeholder={col.placeholder}/>
          case "number":
            return <Form.Control type='number' min={col.restrictions?.min?.value} max={col.restrictions?.max?.value} data-col={col.name} placeholder={col.placeholder}/>
          case "date":
            return <Form.Control type='date' max={col.restrictions?.max?.value} data-col={col.name} placeholder={col.placeholder}/>
          case "select":
            return <Form.Select data-col={col.name}>{col.selectOptions?.map((o=>{return <option key={o} value={o}>{capitalize(o)}</option>}))}</Form.Select>
        }
      }
      
      return <div>{switchColType(col)}<div className='invalid-feedback'></div></div>
    }

    for (const col of this.state.tableDefinition.columns.values()) {
      editRow.push(<td style={customStyles.editCell} key={col.name}>{createInput(col)}</td>)
    }
    editRow.push(<td style={customStyles.editCell} key="controls">
      <Button variant="success" onClick={()=>{this.addRow()}} ><FontAwesomeIcon icon="plus" /></Button>
    </td>)
    return editRow
  }

  prepareValue(value: any) {
    switch (typeof(value)) {
      case "string":
        return value
      case "number":
      case "bigint":
        return value.toString()
      case "object":
        if (value instanceof Date) {
          return new Intl.DateTimeFormat().format(value)
        }
        break;
    }
    throw new Error ("Can't prepare value for display")
  }

  render() {
    let cols = [...this.state.tableDefinition.columns.values()].map((col)=> {return col.name})
    let rows: any[][] = []
    for (const val of this.state.valueRows) {
      let row: any[] = []
      for (const col of cols) {
        row.push(val[col])
      } 
      rows.push(row)
    }

    let editRow = this.generateEditRow()

    return <Card>
      <Card.Body>
        <Form>
          <Table>
            <thead>
              <tr>
                {cols.map((c)=>{return <th style={customStyles.headerCell}  key={c}>{c}</th>})}
                <th>Controls</th>
              </tr>
              <tr ref={this.inputRow}>
                {editRow}
              </tr>
            </thead>
            <tbody>
              {rows.map((r, i)=>{return <tr key={`row-${i}`}>{r.map((v, i)=>{return <td style={customStyles.valueCell} key={`col-${i}`}>{this.prepareValue(v)}</td>})}
              <td><Button variant="danger" onClick={() => {this.removeRow(i)}}><FontAwesomeIcon icon="trash"/></Button></td>
              </tr>})}
            </tbody>
          </Table>
        </Form>
      </Card.Body>
    </Card>
  }
}