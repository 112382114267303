import React, { createRef } from 'react';
import { InputGroup, Form } from 'react-bootstrap';
import { Timestamp } from '../../tools/Timestamp';

interface Props {
    time?: number | Timestamp,
    onChange?: (value: Timestamp)=>void,
}
interface State {
    time: Timestamp
}

export default class TimeSelect extends React.Component <Props, State> {

    hourField : React.RefObject<HTMLInputElement>
    minuteField : React.RefObject<HTMLInputElement>

    constructor (props:Props) {
        super (props)

        this.hourField = createRef()
        this.minuteField = createRef()

        let value = this.props.time

        // if value is a number, convert it to a timestamp
        if (typeof this.props.time === "number"){
          value = new Timestamp(this.props.time)
        }

        this.state = {time: value as Timestamp || new Timestamp(0)}

        this.hourKeyDown = this.hourKeyDown.bind(this)
        this.minuteKeydown = this.minuteKeydown.bind(this)
        this.checkChange = this.checkChange.bind(this)
    }

    allowedKeys = [
        "ArrowRight",
        "ArrowLeft",
        "Delete",
        "Backspace",
        "0", "1", "2", "3", "4", "5", "6", "7", "8", "9",
        "Tab"
    ]

    hourKeyDown(e:React.KeyboardEvent)  {
        let minutesInput = this.minuteField.current

        if (!minutesInput)
            return

        let HTMLtarget: HTMLInputElement = e.currentTarget as HTMLInputElement

        if (HTMLtarget.value.length === 0 && Number.parseInt(e.key) > 2 )
        {
            e.preventDefault()
            return
        }
        if (HTMLtarget.value.length === 1 && HTMLtarget.value[0] === "2" && Number.parseInt(e.key) > 3 )
        {
            e.preventDefault()
            return
        }

        if (HTMLtarget.selectionStart === 2 && HTMLtarget.value.length === 2 && !Number.isNaN(Number.parseInt(e.key)))
        {
            minutesInput.focus()
            minutesInput.selectionStart = 0
            if (minutesInput.value.length === 2)
            {
                minutesInput.selectionEnd = 2
            }
        }
        else if (HTMLtarget.selectionStart === HTMLtarget.value.length && (e.key === "ArrowRight" || e.key === ":"))
        {
            e.preventDefault();
            minutesInput.focus()
            if (HTMLtarget.value.length < 2)
                HTMLtarget.value = HTMLtarget.value.padStart(2, "0")
            return
        }
        else if (e.key === "Tab" && minutesInput.value === "")
        {
            minutesInput.value = "00"
        }
        else if (!this.allowedKeys.includes(e.key))
        {
            e.preventDefault()
            return
        }
    }

    minuteKeydown(e:React.KeyboardEvent)  {
        let hoursInput = this.hourField.current
        let HTMLtarget: HTMLInputElement = e.currentTarget as HTMLInputElement

        if (!hoursInput)
            return

        if (HTMLtarget.value.length === 0 && Number.parseInt(e.key) > 5 )
        {
            e.preventDefault()
            return
        }

        if (HTMLtarget.value.length === 0 && e.key === "Backspace")
        {
            hoursInput.focus()
        }
        else if (HTMLtarget.selectionStart === 0 && e.key === "ArrowLeft")
        {
            e.preventDefault();
            hoursInput.focus()
            return
        }
        else if (e.key === "Tab")
        {
            if (HTMLtarget.value.length > 2)
                HTMLtarget.value = HTMLtarget.value.padStart(2, "0")
        }
        else if (!this.allowedKeys.includes(e.key))
        {
            e.preventDefault()
            return
        }
    }

    checkChange() {
        if (this.minuteField.current?.value.length === 2 && this.hourField.current?.value.length === 2)
        {
            let timestamp = new Timestamp(`${this.hourField.current?.value}:${this.minuteField.current?.value}`)
            this.setState({time: timestamp})
            this.props.onChange && this.props.onChange(timestamp)
        }
    }

    render() {
        return <InputGroup>
            <Form.Control
                value={`${this.state.time.hours}`.padStart(2, "0")}
                maxLength={2} ref={this.hourField as any}
                onKeyUp={this.checkChange}
                onKeyDown={this.hourKeyDown}
                onChange={()=>{}}
                type="text" />
            <InputGroup.Text>:</InputGroup.Text>
            <Form.Control
                value={`${this.state.time.minutes}`.padStart(2, "0")}
                maxLength={2}
                ref={this.minuteField as any}
                onKeyUp={this.checkChange}
                onKeyDown={this.minuteKeydown}
                onChange={()=>{}}
                type="text" />
        </InputGroup>
    }
}
