import { object, string, number, date, InferType, array } from 'yup';


export const AttendanceStatusses = <const> ["Aanwezig - Extern", "Aanwezig - Intern", "Afwezig - Onveroorloofd", "Afwezig - Veroorloofd", "Ingeroosterd", "Overuren", "Vrij - Vakantie", "Vrij - Feestdag", "Ziek"];
export type AttendanceStatus = typeof AttendanceStatusses[number];

export const AttendanceSchema = object({
    id: number(),
    appointment_id: number().required(),
    group_id: number().required(),
    user_id: number().required(),

    date: string().required(),

    start: string().required(),
    stop: string().required(),

    status: string().required().oneOf(AttendanceStatusses)
});

export type Attendance = InferType<typeof AttendanceSchema>;

export const AppointmentTypes = <const> ["single", "schedule"];
export type AppointmentType = typeof AppointmentTypes[number];

export const AppointmentSchema = object({
    id: number(),
    group_id: number().required(),
    user_id: number().required(),

    title: string().required(),

    start_date: string().required(),

    start: string().required(),
    stop: string().required(),

    type: string().required().oneOf(AppointmentTypes),

    meta: object().optional(),
    meta_id: number().optional() // need to improve this with more yup knowldedge
});

export const AppointmentMetaUnitTypes = <const> ["week", "month", "year"];
export type AppointmentMetaUnitType = typeof AppointmentMetaUnitTypes[number];

export const AppointmentMetaSchema = object({
    id: number(),

    end_date: string().required(),

    relative_date: number().required(),
    frequency: number().required(),

    unit: string().required().oneOf(AppointmentMetaUnitTypes)
});

export type Appointment = InferType<typeof AppointmentSchema>;

export type AppointmentMeta = InferType<typeof AppointmentMetaSchema>;

export const GroupRoles = <const> [ "organisation", "other"]; // determines rights for client & admin panel
export type GroupRole = typeof GroupRoles[number];

export const GroupRelationTypes = <const> [ "client", "parent", "other"]; // determines rights for client & admin panel
export type GroupRelationType = typeof GroupRoles[number];

export const GroupStatusses = <const> ["active", "unverified", "banished"]; // status of user
export type GroupStatus = typeof GroupStatusses[number];

export const GroupSchema = object({
    id: number(),
    name: string().required(),
    role: string().required().oneOf(GroupRoles),
    status: string().required().oneOf(GroupStatusses),

    created: string(),
    updated: string(),
});

export type Group = InferType<typeof GroupSchema>;

export const GroupRelationSchema = object({
    id: number(),
    group_id: number().required(),
    relation_id: number().required(),
    type: string().required().oneOf(GroupRelationTypes),
});

export type GroupRelation = InferType<typeof GroupRelationSchema>;

export const UserTypes = <const> ["admin", "user", "banana", "guest", "moderator"]; // determines rights for client & admin panel
export type UserType = typeof UserTypes[number];

export const UserRoles = <const> [ "employee", "client", "intern", "guest"]; // role towards a group
export type UserRole = typeof UserRoles[number];

export const UserGroupRoles = <const> [ "employee", "client", "intern", "guest", "volunteer" ]; // determines rights towards group
export type UserGroupRole = typeof UserGroupRoles[number];

export const UserStatusses = <const> ["active", "unverified", "banished", "deactivated"]; // status of user
export type UserStatus = typeof UserStatusses[number];

export const UserGroupSchema = object({
    id: number(),
    user_id: number(), // not required.
    group_id: number().required(),
    role: string().required().oneOf(UserGroupRoles),
});

export type UserGroup = InferType<typeof UserGroupSchema>;

export const UserSchema = object({
    id: number(),

    email: string().required().email(),
    password: string().required().min(8),

    name: string(),

    type: string().required().oneOf(UserTypes),
    status: string().required().oneOf(UserStatusses),
    role: string().required().oneOf(UserRoles),

    groups: array().of(UserGroupSchema),
    relations: array(),

    created: string(),
    updated: string(),
});

export type User = InferType<typeof UserSchema>;
