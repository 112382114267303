
import React from 'react';
import {  Alert, Badge, Button, Card,  Col,  Form,  Row,  Table, Tooltip } from 'react-bootstrap';

import APIClient from '../../inc/APIClient';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Appointment, Attendance, AttendanceStatusses } from '../../inc/types';
import { Link } from 'react-router-dom';
import { dateFormat, dayName, getWeekDates, getWeekNumber, minutesToTimeStr, timeStrToMinutes, withRouterParams } from '../../inc/helpers';


let api = new APIClient();

interface Parameters { user_id : string; unit?: string; }
interface Props { params?: Parameters; }

interface State {
    appointments?: Array<Appointment>;
    user?: any;
    appointmentAttendance: Array<any>;
    attendanceData?: Array<Attendance>;

    // add attendance form
    attendanceDate?: string;
    attendanceStart?: string;
    attendanceStop?: string;
    attendanceStatus?: string;
}

class AppointmentUserOverview extends React.Component <Props, State> {

    constructor(props: Props){
      super(props);


      this.state = {
        appointmentAttendance: [],

        attendanceDate: new Date().toISOString().split("T")[0],
        attendanceStart: '09:00',
        attendanceStop: '17:00',
        attendanceStatus: 'Aanwezig - Intern'
      };

      this.loadAppointments = this.loadAppointments.bind(this);
      this.loadAttendance = this.loadAttendance.bind(this);
      this.setAttendance = this.setAttendance.bind(this);
      this.addAttendance = this.addAttendance.bind(this);
    }

    async loadAttendance(){
      let id = this.props.params?.user_id;
      if (id){
        api.AttendanceGetUser(parseInt(id)).then((res) => {
          this.setState({ attendanceData: res.data });
        }).catch((err) => {
            console.log(err);
        });
      }
    }

    async loadAppointments(){
      if (this.props.params && this.props.params.user_id){
        api.UserGet(parseInt(this.props.params?.user_id)).then((res) => {
          this.setState({ user: res.data[0] });
        }).catch((err) => {
            console.log(err);
        });

        api.AppointmentGetUser(parseInt(this.props.params?.user_id)).then(async (res) => {

          let appointmentAttendance: any = [];
          for (let i = 0; i < res.data.length; i++) {
            const appointment = res.data[i];
            if(appointment.attendances && appointment.attendances.length > 0){
              appointmentAttendance[appointment.id] = appointment.attendances[0].status;
            }
          }
          this.setState({ appointments: res.data, appointmentAttendance: appointmentAttendance ? appointmentAttendance : [] });
        }).catch((err) => {
            console.log(err);
        });
      }
    }

    async setAppointmentAttendance(status:string, appointment_id:number){
      api.AppointmentGet(appointment_id).then((res) => {
        let appointment = res.data[0];

        // same day this week from appointment.start_date
        let date = new Date(appointment.start_date);
        let day = date.getDay();
        let diff = date.getDate() - day + (day == 0 ? -6:1); // adjust when day is sunday
        let weekDate = new Date(date.setDate(diff));
        let relativeDate = new Date(weekDate);
        relativeDate.setDate(weekDate.getDate() + day);
        console.log(relativeDate);


        this.setAttendance(status, relativeDate, appointment.start, appointment.stop, appointment_id);
      }).catch((err) => {
          console.log(err);
      });
    }


    async setAttendance(status:string, date:Date, start:number, stop:number, appointment_id?:number){

      if(appointment_id && appointment_id > 0){
        let attendances = await api.AttendanceGetByAppointment(appointment_id);
        if(attendances.data && attendances.data.length > 0){
          for (let i = 0; i < attendances.data.length; i++) {
            const attendance = attendances.data[i];
            api.AttendanceDelete(attendance.id);
          }
        }
      }

      let attendance = {
          status: status,
          appointment_id: appointment_id,
          group_id: api.activeGroup,
          user_id: this.props.params?.user_id,
          date: date,
          start: start,
          stop: stop
      }

      api.AttendanceCreate(attendance as unknown as Attendance).then((res) => {
        this.loadAppointments();
        console.log(res);
      }).catch((err) => {
        console.log(err);
      });
    }

    addAttendance(){

      let attendance = {
          status: this.state.attendanceStatus,
          appointment_id: null,
          group_id: api.activeGroup,
          user_id: this.props.params?.user_id,
          date: this.state.attendanceDate,
          start: timeStrToMinutes(this.state.attendanceStart || "" ),
          stop: timeStrToMinutes(this.state.attendanceStop || "")
      }
      console.log(attendance);

      api.AttendanceCreate(attendance as unknown as Attendance).then((res) => {
        this.loadAttendance();
        console.log(res);
      }).catch((err) => {
        console.log(err);
      });

    }

    deleteAttendance(id:number){
      api.AttendanceDelete(id).then((res) => {
        this.loadAttendance();
        console.log(res);
      }).catch((err) => {
        console.log(err);
      });
    }

    componentDidMount(): void {
      this.loadAppointments();
      this.loadAttendance();
    }

    componentDidUpdate(prevProps: Readonly<Props>, _prevState: Readonly<State>, _snapshot?: any): void {
      if (this.props.params?.user_id !== prevProps.params?.user_id) {
        this.loadAppointments();
        this.loadAttendance();
      }
    }

    render(){

        let row = (Appointment: any) => {



            return <tr key={Appointment.id}>
                <td>
                  {dayName(new Date(Appointment.start_date))}
                  {Appointment.attendances && Appointment.attendances.length > 0 && <span className='float-end'>
                    <Badge pill>{Appointment.attendances[0].status}</Badge>
                  </span>}


                </td>
                <td>{minutesToTimeStr(Appointment.start)}</td>
                <td>{minutesToTimeStr(Appointment.stop)}</td>

                <td>

                <Row>
                  <Col md={11}>
                    <Form.Select
                      value={this.state.appointmentAttendance[Appointment.id] ? this.state.appointmentAttendance[Appointment.id] : "Ingeroosterd"}
                      onChange={(e) => { let values = this.state.appointmentAttendance; values[Appointment.id] = e.target.value; this.setState({ appointmentAttendance: values})  }}>
                      {AttendanceStatusses.map((status, index) => {
                        return <option key={index}>{status}</option>
                      })}
                    </Form.Select>
                  </Col>
                  <Col md={1}>
                    <Button variant="info" size="sm" className='float-end' onClick={
                      (e) => {
                        let status = this.state.appointmentAttendance[Appointment.id || 0];
                        if (status){
                          this.setAppointmentAttendance(status, Appointment.id || 0);
                        }
                      }
                    }><FontAwesomeIcon icon="floppy-disk"/></Button>
                  </Col>
                </Row>
                </td>
            </tr>
        }

        return <Card>
            <Card.Header>
                <FontAwesomeIcon icon="note-sticky" />&nbsp; Appointments overview
                <Link to={`/users/edit/${this.props.params?.user_id}`} className='float-end'><Button variant="success" size="sm" className="float-end"><FontAwesomeIcon icon="pencil"/> Gebruiker bewerken</Button></Link>
            </Card.Header>
            <Card.Body>
                <Alert variant='info'>
                  <Alert.Heading><FontAwesomeIcon icon="info" /> Planning voor gebruiker {this.state.user?.name} <strong>Week { getWeekNumber() }</strong></Alert.Heading>

                </Alert>


                <Card>
                  <Card.Body>
                    <span><strong>Planning {this.state.user?.name}</strong></span>
                    <span className='float-end'>
                      Week { getWeekNumber() }
                    </span>
                    <hr />

                      <Table striped bordered hover >
                      <thead>
                          <tr>
                              <th>Dag</th>
                              <th>Van</th>
                              <th>Tot</th>
                              <th>Actions</th>
                          </tr>
                      </thead>
                      <tbody>
                          {this.state.appointments && this.state.appointments.map((Appointment) => {
                              return row(Appointment);
                          })}

                          {/* form row */}
                          <tr>
                              <td>
                                <Form.Select value={this.state.attendanceDate} onChange={(e) => {this.setState({ attendanceDate : e.target.value }); console.log(this.state, e.target.value) } }>
                                  { getWeekDates().map((date, index) => {
                                    return <option value={dateFormat(date, "Y-m-d")} key={index}>{dayName(date)} ({dateFormat(date)})</option>
                                  })  }
                                </Form.Select>
                              </td>
                              <td>
                                {/* type is time */}
                                <Form.Control type="time" placeholder="Start" value={this.state.attendanceStart} onChange={(e) => {this.setState({ attendanceStart : e.target.value })} } />
                              </td>
                              <td>
                                {/* type is time */}
                                <Form.Control type="time" placeholder="Stop" value={this.state.attendanceStop} onChange={(e) => {this.setState({ attendanceStop : e.target.value })} } />
                              </td>
                              <td>
                                <Row>
                                  <Col md={11}>
                                  <Form.Select  value={this.state.attendanceStatus} onChange={(e) => {this.setState({ attendanceStatus : e.target.value })} }>
                                    {AttendanceStatusses.map((status, index) => {
                                      return <option key={index}>{status}</option>
                                    })}
                                  </Form.Select>
                                  </Col>
                                  <Col md={1}>
                                    <Button variant="success" size="sm" className='float-end' onClick={this.addAttendance}><FontAwesomeIcon icon="plus"/></Button>
                                  </Col>
                                </Row>

                              </td>
                          </tr>

                      </tbody>
                    </Table>

                  </Card.Body>
                </Card>
                <br/>

                <Card>
                  <Card.Body>
                    <span><strong>Aanwezigheid {this.state.user?.name}</strong></span>
                    <span className='float-end'>
                      Week { getWeekNumber() }
                    </span>
                    <hr />

                      <Table striped bordered hover >
                      <thead>
                          <tr>
                              <th>Datum</th>
                              <th>Dag</th>
                              <th>Van</th>
                              <th>Tot</th>
                              <th>Status</th>
                              <th></th>

                          </tr>
                      </thead>
                      <tbody>
                          {this.state.attendanceData && this.state.attendanceData.map((attendance) => {
                              return <tr key={attendance.id}>
                                <td>{dateFormat(attendance.date)}</td>
                                <td>{dayName(new Date(attendance.date))}</td>
                                <td>{minutesToTimeStr(attendance.start)}</td>
                                <td>{minutesToTimeStr(attendance.stop)}</td>
                                <td>{attendance.status}</td>
                                <td><Button size="sm" className='float-end' variant="danger" onClick={(e) => { this.deleteAttendance(attendance.id || 0) }}><FontAwesomeIcon icon="trash" /> </Button></td>
                              </tr>
                          }
                          )}
                          </tbody>
                    </Table>

                  </Card.Body>
                </Card>




            </Card.Body>
        </Card>


    }
}


export default withRouterParams(AppointmentUserOverview);
