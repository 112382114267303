
import React from 'react';
import { Alert, Button, Card, Col, Form, Row, Table } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import { ObjectSchema } from 'yup';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import APIClient from '../../inc/APIClient';
import { capitalize, withRouterParams } from '../../inc/helpers';
import { Appointment, AppointmentMeta, AppointmentMetaUnitType, AppointmentSchema } from '../../inc/types';

// id = appointment id - optional url param.
// group_id = currently selected group - localstorage/api
// user_id = url param.

let api = new APIClient()

interface Params { id?:string; user_id?: string }

interface Props { params?: Params; }

interface State extends Appointment {
    errors?: string[];
    messages?: string[];
    end_date?: string; // meta
    relative_date: number;
    unit: AppointmentMetaUnitType;
    frequency: number;
    days: Array<number>;
    appointment?: Appointment;
    appointments?: Array<Appointment>;
    user?: any;
}

 class AppointmentForm extends React.Component <Props, State> {
    schema: ObjectSchema<any>;

    constructor(props: Props){
        super(props);

        this.schema = AppointmentSchema;


        // current time in hh:mm
        let now = new Date().toISOString().split('T')[1].split('.')[0];
        now = now.substring(0, now.length - 3);

        this.state = {
          group_id: api.activeGroup,
          user_id: this.props.params?.user_id ? parseInt(this.props.params.user_id) : 0,
          title: "",
          start_date: new Date().toISOString().split('T')[0],
          end_date: new Date().toISOString().split('T')[0],
          start: now,
          stop: now,
          type: "single",
          relative_date: 0,
          unit: "week",
          frequency: 1,
          days: [],
        }


        this.onSubmit = this.onSubmit.bind(this);
        this.fetchAppointment = this.fetchAppointment.bind(this);
        this.fetchUser = this.fetchUser.bind(this);

    }

    async onSubmit (values: Partial<State>, _form: any) {
      console.log(values);
      if(!values.user_id || !values.start || !values.stop) { return; }

      let startMinutes = parseInt(values.start.split(':')[0]) * 60 + parseInt(values.start.split(':')[1]);
      let stopMinutes = parseInt(values.stop.split(':')[0]) * 60 + parseInt(values.stop.split(':')[1]);

      let appointment = {
        id: this.state.id,
        group_id: api.activeGroup,
        user_id: values.user_id,
        title: values.title,
        start_date: new Date(values.start_date || ""),
        start: startMinutes,
        stop: stopMinutes,
        type: values.type,
        meta: {}
      }

      if (values.type === "schedule") {
        appointment = {
          ...appointment,
          meta: {
            end_date: new Date(values.end_date || ""),
            relative_date: 1,
            unit: values.unit,
            frequency: values.frequency,
            // days: values.days
          }
        }
      }

      if (this.state.id) {
        api.AppointmentUpdate(appointment).then((res: any) => {
          this.setState({ messages: ["Afspraak bijgewerkt."] });
          this.fetchAppointment(this.state.id || 0);
        }).catch((err) => this.setState({ errors: [err] }))
      } else {
        api.AppointmentCreate(appointment).then((res: any) => {
          this.setState({ messages: ["Afspraak aangemaakt."] });
          this.fetchAppointment(res.data[0].id);
        }).catch((err) => this.setState({ errors: [err] }))
      }
    }


    fetchUser(user_id:number) {
      api.UserGet(user_id).then((res: any) => { this.setState({ user: res.data[0] }); }).catch((err) => this.setState({ errors: [err] }))
      api.AppointmentGetUser(user_id).then((res: any) => { this.setState({ appointments: res.data ? res.data : [] }); }).catch((err) => this.setState({ errors: [err] }))
    }

    fetchAppointment(id: number) {
      api.AppointmentGet(id).then((res: any) => {

        let start = `${Math.floor(res.data[0].start / 60)}:${res.data[0].start % 60}`
        let stop = `${Math.floor(res.data[0].stop / 60)}:${res.data[0].stop % 60}`

        let dbAppointment = {
          group_id: res.data[0].group_id,
          user_id:  res.data[0].user_id,
          title: res.data[0].title,
          start_date: new Date(res.data[0].start_date).toISOString().split('T')[0],
          end_date: new Date(res.data[0].meta?.end_date).toISOString().split('T')[0],
          start: start,
          stop: stop,
          type: res.data[0].type,
          relative_date:  res.data[0].meta?.relative_date,
          unit:  res.data[0].meta?.unit,
          frequency:  res.data[0].meta?.frequency,
          days:  res.data[0].meta?.days ? res.data[0].meta?.days : [],
        }

        this.setState({ ...dbAppointment, id: id });

       }).catch((err) => this.setState({ errors: [err] }))
    }



    componentDidMount(): void {

      // parse params
      if (this.props.params && this.props.params.id) {
        let id = parseInt(this.props.params.id);
        if (id > 0 && !isNaN(id)) {
          this.fetchAppointment(id)
        }
      }

      // user_id
      if (this.props.params && this.props.params.user_id) {
        let user_id = parseInt(this.props.params.user_id);
        if (user_id > 0 && !isNaN(user_id)) {
          this.fetchUser(user_id)
        }
      }


    }

    render(){
        let activeGroupName = api.user?.groups?.map((group:any) => { if (group.id === api.activeGroup) { return group.name; } })

        return <><Card>
            <Card.Header><span className='me-2' style={{display: "inline-block"}}><FontAwesomeIcon icon="user" /></span> Appointment</Card.Header>
            <Card.Body>
                { this.state.errors && <Alert variant="danger">{this.state.errors}</Alert> }
                { this.state.messages && <Alert variant="success">{this.state.messages }</Alert> }
                {/* enable reinitialize allows reloading initial values when state updates */}
                <Formik enableReinitialize={true} validationSchema={this.schema} onSubmit={this.onSubmit} initialValues={this.state}>
                    {({
                        handleSubmit, handleChange, handleBlur, values, touched, isValid, errors, setFieldValue
                    }) => (
                        <Form noValidate onSubmit={handleSubmit}>

                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column xs="1">User</Form.Label>
                                <Col xs="11">

                                  { this.state.user && <>
                                    <p>Afspraak inplannen voor {this.state.user.email} ({ this.state.user_id }) bij {activeGroupName} ({api.activeGroup}) <br/>
                                      Deze gebruiker heeft {this.state.appointments?.length} afspraken.
                                    </p>
                                  </>}

                                </Col>

                            </Form.Group>


                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column xs="1">Title</Form.Label>
                                <Col xs="11">
                                    <Form.Control
                                        type="text"
                                        name="title"
                                        value={values.title}
                                        onChange={handleChange}
                                        onClick={handleBlur}
                                        isInvalid={touched.title && !!errors.title}
                                        placeholder="Dagbesteding"/>
                                    <Form.Control.Feedback type="invalid">{errors.title}</Form.Control.Feedback>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column xs="1">(Start) Datum</Form.Label>
                                <Col xs="11">
                                    <Form.Control
                                        type="date"
                                        name="date"
                                        value={values.start_date}
                                        onChange={(e) => {  setFieldValue("start_date", e.target.value); }} // not sure whats going on here?!
                                        isInvalid={!!errors.start_date}
                                        />
                                    <Form.Control.Feedback type="invalid">{errors.start_date}</Form.Control.Feedback>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-2">
                                <Col xs="6">
                                  <Row>
                                    <Form.Label column xs="2">Start Tijd</Form.Label>
                                    <Col>
                                      <Form.Control
                                          type="time"
                                          name="start"
                                          value={values.start}
                                          onChange={handleChange}
                                          isInvalid={!!errors.start}
                                          />
                                      <Form.Control.Feedback type="invalid">{errors.start}</Form.Control.Feedback>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col xs="6">
                                  <Row>
                                    <Form.Label column xs="2">Eind Tijd</Form.Label>
                                    <Col>
                                      <Form.Control
                                          type="time"
                                          name="stop"
                                          value={values.stop}
                                          onChange={handleChange}
                                          isInvalid={!!errors.start}
                                          />
                                      <Form.Control.Feedback type="invalid">{errors.stop}</Form.Control.Feedback>
                                    </Col>
                                  </Row>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column xs="1">Type</Form.Label>
                                <Col xs="11">
                                    <Form.Select
                                        name="type"
                                        value={values.type}
                                        onChange={handleChange}
                                        isInvalid={!!errors.type}
                                        >
                                        <option value="schedule">Herhalende Afspraak</option>
                                        <option value="single">Eenmalige Afspraak</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">{errors.type}</Form.Control.Feedback>
                                  </Col>
                            </Form.Group>

                            { values.type === "schedule" && <>
                              <Card>
                                <Card.Body>
                                  <Form.Group as={Row} className="mb-2">
                                      <Form.Label column xs="1">Eind datum</Form.Label>
                                      <Col xs="11">
                                          <Form.Control
                                              type="date"
                                              name="end_date"
                                              value={values.end_date}
                                              onChange={handleChange}
                                              isInvalid={!!errors.end_date}
                                              />
                                          <Form.Control.Feedback type="invalid">{errors.end_date}</Form.Control.Feedback>
                                      </Col>
                                  </Form.Group>
                                  <Form.Group as={Row} className="mb-2">
                                      <Form.Label column xs="1">Elke</Form.Label>
                                      <Col xs="1">
                                          <Form.Control
                                              type="number"
                                              name="frequency"
                                              value={values.frequency}
                                              onChange={handleChange}
                                              isInvalid={!!errors.frequency}
                                              />
                                          <Form.Control.Feedback type="invalid">{errors.frequency}</Form.Control.Feedback>
                                      </Col>
                                      <Col xs="3">
                                        <Form.Select
                                            name="unit"
                                            value={values.unit}
                                            onChange={handleChange}
                                            isInvalid={!!errors.unit}
                                            >
                                            <option value="day">Dagen</option>
                                            <option value="week">Weken</option>
                                            <option value="month">Maanden</option>
                                            <option value="year">Jaar</option>
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">{errors.unit}</Form.Control.Feedback>
                                      </Col>


                                  </Form.Group>
                                </Card.Body>
                              </Card>


                            </>}


                            <br/>
                            <Button type="submit" disabled={!isValid}><FontAwesomeIcon icon="floppy-disk" /> &nbsp;Save</Button>

                        </Form>
                    )}
                </Formik>

            </Card.Body>
        </Card>
        </>


    }
}
export default withRouterParams(AppointmentForm);;
