export interface iVector {
    x: number
    y: number
}

export default class Vector implements iVector {
    x: number
    y: number

    static fromInterface (obj: iVector) {
        return obj instanceof (Vector) ? obj : new Vector(obj.x, obj.y) 
    }

    constructor (x: number, y: number)
    {
        this.x = x
        this.y = y
    }
}