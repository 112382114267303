
import React from 'react';
import {  Routes, Route, useParams } from 'react-router-dom';
import AppointmentOverview from './overview';
import { withRouterParams } from '../../inc/helpers';
import  AppointmentForm  from './form';
import AppointmentUserOverview  from './userOverview';

interface Parameters { }
interface Props { params?: Parameters; }
interface State { }


class AppointmentRouter extends React.Component <Props, State> {

    render(){



        return <Routes>
            <Route path="/" element={<AppointmentOverview />}> </Route>
            <Route path="/user/:user_id" element={<AppointmentUserOverview />}> </Route>
            <Route path="/for/:user_id" element={<AppointmentForm />}> </Route>
            <Route path="/:user_id/:id" element={<AppointmentForm />}> </Route>

          </Routes>
    }
}


export default withRouterParams(AppointmentRouter);
