import React from 'react';
import { Alert, Button, Card, Form, Row, Col } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import { ObjectSchema } from 'yup';
import TimeSelect from './components/timeSelect';
import { Timestamp } from '../tools/Timestamp';

interface Props { } 
interface State {    
    error?: string;
    message?: string; 
    data: {
        name?: string,
        age?: number,
        password?: string,
        fAnimal?: string,
        breakfast?: {
            food?: string,
            drink?: string,
            extra?: string
        },
        from?: Timestamp,
        till?: Timestamp
    }
}

export default class TestForm extends React.Component <Props, State> {
    schema: ObjectSchema<any>;
    animals = [
        "Cat",
        "Dog",
        "Crow",
        "Rat",
        "Spider",
        "Ferret",
        "Eagle",
        "Dolphin",
        "Horse",
        "Zebra",
        "Ape"
    ]
    breakfast = {
        food: [
            "Cerial",
            "PB&J",
            "Pancakes",
            "Grilled Cheese"
        ],
        drink: [
            "Water",
            "Milk",
            "Coffee"
        ],
        extra: [
            "Muffin",
            "Doughnut",
            "Brownie"
        ]
    }

    constructor(props: Props){
        super(props);

        this.state = { // these get uses as initial values for the form. (fetch from api in other cases)
            error : "",
            message: "",
            data: {
                name: "",
                age: 0,
                password: "",
                fAnimal: "",
                breakfast: {
                    food: "",
                    drink: "",
                    extra: ""
                },
                from: new Timestamp(0),
                till: new Timestamp(0)
            }
        };

        this.schema = yup.object().shape({
            name: yup.string()
                .min(2, "Please provide a longer name")
                .max(32,"Please provide a shorter name")
                .required("A name is required"),

            age: yup.number()
                .integer("Please provide an age as a whole number")
                .lessThan(120, "You're not that old")
                .moreThan(12, "Not for babies")
                .required("Age is required"),

            password: yup.string()
                .min(8, "Would you entrust your data to a password shorter than 8 characters?")
                .max(128, "Please don't overburden our database")
                .required("Protect your data ffs"),

            fAnimal: yup.string()
                .oneOf(this.animals)
                .required(),
            
            breakfast: yup.object().shape({
                food: yup.string()
                    .oneOf(this.breakfast.food)
                    .required(),

                drink: yup.string()
                    .oneOf(this.breakfast.drink)
                    .required(),

                extra: yup.string()
                    .oneOf(this.breakfast.extra)
                    .required(),
            }).required(),

            from: yup.object().shape({
                totalMinutes: yup.number()
                    .lessThan(1440, "Invalid Timestamp")
                    .moreThan(0, "Invalid Timestamp")
                    .required()
            }).required(),

            till: yup.object().shape({
                totalMinutes: yup.number()
                    .lessThan(1440, "Invalid Timestamp")
                    .moreThan(-1, "Invalid Timestamp")
                    .required()
            }).required()
        })

        this.onSubmit = this.onSubmit.bind(this)
    }

    onSubmit(values: {
        name?: string,
        age?: number,
        password?: string,
        fAnimal?: string,
        breakfast?: {
            food?: string,
            drink?: string,
            extra?: string
        },
        from?: Timestamp,
        till?: Timestamp
    }) {
        this.setState({data: values})
        console.log(values)
    }

    render(){
        return  <>
            <Card>
                <Card.Header style={{textAlign: "center"}}>My bs form</Card.Header>
                <Card.Body>
                    { this.state.error && <Alert variant="danger">{this.state.error}</Alert> }
                    { this.state.message && <Alert variant="success">{this.state.message}</Alert> }
                    <Formik validationSchema={this.schema} onSubmit={this.onSubmit} initialValues={this.state.data}>
                        {({
                            handleSubmit, handleChange, handleBlur, setFieldValue, values, touched, isValid, errors, 
                        })=>
                        (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column xs="2">Name</Form.Label>
                                    <Col xs="10">
                                        <Form.Control placeholder="Please enter your name" type="text" name="name" value={values.name} onKeyUp={handleBlur} onChange={handleChange} isInvalid={!!touched.name && !!errors.name}/>
                                        <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column xs="2">Age</Form.Label>
                                    <Col xs="10">
                                        <Form.Control placeholder="Please enter your age" type="number" name="age" value={values.age === 0 ? "" : values.age} onKeyUp={handleBlur} onChange={handleChange} isInvalid={!!touched.age && !!errors.age}/>
                                        <Form.Control.Feedback type="invalid">{errors.age}</Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column xs="2">Password</Form.Label>
                                    <Col xs="10">
                                        <Form.Control placeholder="Please enter your password" type="password" name="password" value={values.password} onKeyUp={handleBlur} onChange={handleChange} isInvalid={!!touched.password &&  !!errors.password}/>
                                        <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column xs="2">Favorite Animal</Form.Label>
                                    <Col xs="10">
                                        <Form.Select aria-label="Favorite Animal" name="fAnimal" value={values.fAnimal} onBlur={handleBlur} onChange={handleChange} isInvalid={!!touched.fAnimal && !!errors.fAnimal}>
                                            <option hidden>Please select your favorite animal</option>
                                            {this.animals.sort().map((a)=>{return <option key={a} value={a}>{a}</option>})}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column xs={2}>
                                        Breakfast choice
                                    </Form.Label>
                                    <Col>
                                        <Form.Label>
                                            Food
                                        </Form.Label>
                                        {this.breakfast.food.map((b)=>{return <Form.Check
                                            key={b}
                                            type="radio"
                                            label={b}
                                            value={b}
                                            onChange={handleChange}
                                            checked={values.breakfast?.food === b}
                                            name="breakfast.food"
                                            />})}
                                    </Col>
                                    <Col>
                                        <Form.Label>
                                            Drink
                                        </Form.Label>
                                        {this.breakfast.drink.map((b)=>{return <Form.Check
                                            key={b}
                                            type="radio"
                                            label={b}
                                            value={b}
                                            onChange={handleChange}
                                            checked={values.breakfast?.drink === b}
                                            name="breakfast.drink"
                                            />})}
                                    </Col>
                                    <Col>
                                        <Form.Label>
                                            Extra
                                        </Form.Label>
                                        {this.breakfast.extra.map((b)=>{return <Form.Check
                                            key={b}
                                            type="radio"
                                            label={b}
                                            value={b}
                                            onChange={handleChange}
                                            checked={values.breakfast?.extra === b}
                                            name="breakfast.extra"
                                            />})}
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column xs="2">Attendance Time</Form.Label>
                                    <Col>
                                        <Row className="mb-1">
                                            <Form.Label column xs="1">From</Form.Label>
                                            <Col xs="3">
                                                <TimeSelect time={values.from} onChange={(value:Timestamp)=>{setFieldValue("from", value)}}></TimeSelect>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Form.Label column xs="1">Till</Form.Label>
                                            <Col xs="3">
                                                <TimeSelect time={values.till} onChange={(value:Timestamp)=>{setFieldValue("till", value)}}></TimeSelect>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Form.Group>
                                <Button onClick={()=>{console.log(errors)}}>log errors</Button>
                                <Button type="submit" disabled={!isValid}>submit</Button>
                            </Form>
                        )}
                    </Formik>
                </Card.Body>
            </Card>
        </>
    }
}