
import React from 'react';
import {  Breadcrumb, Button, Card,  Col,  Row,  Table, Form, Dropdown } from 'react-bootstrap';
import APIClient from '../../inc/APIClient';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { User } from '../../inc/types';
import { Link } from 'react-router-dom';
import { withRouterParams } from '../../inc/helpers';

interface Parameters { userType : string; }
interface Props { params?: Parameters; }

interface State {
    users?: Array<User>;
}

export class UserOverview extends React.Component <Props, State> {
    api: APIClient;

    constructor(props: Props){
        super(props);

        this.api = new APIClient();
        this.state = {};
        this.loadUsers = this.loadUsers.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
    }

    async loadUsers(){
      this.api.UserGetAll().then((res) => {
        if (this.props.params?.userType){
          // filter users that have group relation 1 and then by type compared to params
          res.data = res.data.filter((user:any) => {
            let data = user.groups.find((relation:any) => { return relation.group_id == this.api.activeGroup; })
            return (data && data.role == this.props.params?.userType);
          });
        }else{
          // filter users that have group relation 1 and then by type compared to params
          res.data = res.data.filter((user:any) => {
            let data = user.groups.find((relation:any) => { return relation.group_id == this.api.activeGroup; })
            if (data){
              return true;
            }
          });
        }

        this.setState({ users: res.data });
      }).catch((err) => {
          console.log(err);
      });
    }

    componentDidMount(): void {
      this.loadUsers();
    }

    componentDidUpdate(prevProps: Readonly<Props>, _prevState: Readonly<State>, _snapshot?: any): void {
      if (this.props.params?.userType !== prevProps.params?.userType) {
        this.loadUsers();
      }

      let api = new APIClient();
      if (api.activeGroup !== this.api.activeGroup) {
        this.loadUsers();
      }

    }

    deleteUser(id: number){
      this.api.UserDelete(id).then((res) => {
        this.loadUsers();
      }).catch((err) => {
          console.log(err);
      });
    }

    render(){

        let row = (user: User) => {

            let roles:any = user.groups?.filter((relation:any) => { return relation.group_id == this.api.activeGroup; })
            roles = roles.map((relation:any) => { return relation.role; }).join(', ');

            return <tr key={user.id}>
                <td>{user.email}</td>
                <td>{user.name}</td>
                <td>{user.groups?.find((relation:any) => { return relation.group_id == this.api.activeGroup; })?.role }</td>
                <td>{user.groups?.length}</td>
                <td>
                    <Link className='me-1' to={`/appointments/user/${user.id}`}><Button variant="info"  size="sm"><FontAwesomeIcon icon="calendar-days"/> Weekplanning</Button></Link>
                    <Link className='me-1' to={`/appointments/for/${user.id}`}><Button variant="success"  size="sm"><FontAwesomeIcon icon="calendar-plus"/> Afspraak</Button></Link>
                </td>
                <td>
                    {/* <Link className='me-1' to="/users"><Button variant="info"  size="sm"><FontAwesomeIcon icon="info"/></Button></Link> */}
                    <Link className='me-1' to={`/users/edit/${user.id}`}><Button  variant="warning" size="sm"><FontAwesomeIcon icon="pencil"/></Button></Link>
                    {/* <Link className='me-1' to="/users"><Button variant="dark"  size="sm"><FontAwesomeIcon icon="ban"/></Button></Link> */}
                    <Link className='me-1' to="" onClick={(e) => { e.preventDefault(); this.deleteUser(user.id || 0) }}><Button variant="danger"  size="sm"><FontAwesomeIcon icon="trash"/></Button></Link>
                </td>
            </tr>
        }

        return <>
        <Row>
            <Col md={10}>
              <Breadcrumb >
                  <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                  <Breadcrumb.Item active>Gebruikers</Breadcrumb.Item>
                  { this.props.params?.userType && <Breadcrumb.Item active>{this.props.params?.userType}</Breadcrumb.Item> }
              </Breadcrumb>
            </Col>
        </Row>
        <hr/>
        <Row>
          <Col md={8}>
            <div className='search-bar float-start w-50 me-1'>
              <Form.Control type="search" size="sm" placeholder="Zoeken..." />
              <span className='search-icon'><FontAwesomeIcon icon="chart-bar" /></span>
            </div>
            {/* dropdown button select */}
            <Dropdown className='float-start me-1'>
              <Dropdown.Toggle variant="secondary" id="dropdown-basic" size='sm'>
                <FontAwesomeIcon icon="filter" /> Status
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="#/action-1">Alles</Dropdown.Item>
                <Dropdown.Item href="#/action-2">Actief</Dropdown.Item>
                <Dropdown.Item href="#/action-3">Inactief</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown className='float-start'>
              <Dropdown.Toggle variant="secondary" id="dropdown-basic" size='sm'>
                <FontAwesomeIcon icon="arrow-down-wide-short" /> Rol
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="#/action-1">Alles</Dropdown.Item>
                <Dropdown.Item href="#/action-2">Actief</Dropdown.Item>
                <Dropdown.Item href="#/action-3">Inactief</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>



          </Col>

          <Col md={4}>
            <Link to="/users/add" className='float-end' style={{marginRight:"-10px"}}><Button variant="primary" size="sm"><FontAwesomeIcon icon="user-plus"/> Gebruiker toevoegen</Button></Link>
          </Col>
        </Row>

        <hr/>
        <Card>
            <Card.Body>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>E-Mail</th>
                            <th>Name</th>
                            <th>Rol</th>
                            <th>Groepen</th>
                            <th>Aanwezigheid</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.users && this.state.users.map((user) => {
                            return row(user);
                        })}
                        { this.state.users && this.state.users.length == 0 && <tr><td colSpan={5}>Geen gebruikers gevonden</td></tr>}
                    </tbody>
                </Table>

            </Card.Body>
        </Card>

        </>


    }
}


export default withRouterParams(UserOverview);
