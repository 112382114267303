
import React from 'react';
import { Breadcrumb, Button, Card, Col, Container, Form, ListGroup, Row, Table } from 'react-bootstrap';
import APIClient from '../inc/APIClient';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';


let api = new APIClient();

interface Props {}
interface State {}

class SplitView extends React.Component <Props, State> {


  search() {
    return <>
      <Form.Control type="search" placeholder="zoeken..." />
    </>
  }


  table() {
    return <Card className='mb-4'>
        <Card.Body>
          <h3>Gebruikers</h3>
          <hr />

          <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Username</th>
              <th>Snelfuncties</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>Mark</td>
              <td>Otto</td>
              <td>@mdo</td>
              <td>
                  <Button variant="info" size="sm"><FontAwesomeIcon icon="info"/></Button>&nbsp;
                  <Link to={`/users/1`}><Button variant="warning" size="sm"><FontAwesomeIcon icon="pencil"/></Button>&nbsp;</Link>
                  <Button variant="dark" size="sm"><FontAwesomeIcon icon="ban"/></Button>&nbsp;
                  <Button variant="danger" size="sm"><FontAwesomeIcon icon="trash"/></Button>&nbsp;
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td>Jacob</td>
              <td>Thornton</td>
              <td>@fat</td>
              <td>
                  <Button variant="info" size="sm"><FontAwesomeIcon icon="info"/></Button>&nbsp;
                  <Link to={`/users/1`}><Button variant="warning" size="sm"><FontAwesomeIcon icon="pencil"/></Button>&nbsp;</Link>
                  <Button variant="dark" size="sm"><FontAwesomeIcon icon="ban"/></Button>&nbsp;
                  <Button variant="danger" size="sm"><FontAwesomeIcon icon="trash"/></Button>&nbsp;
              </td>
            </tr>
            <tr>
              <td>3</td>
              <td>Larry the Bird</td>
              <td>Larry the Bird</td>
              <td>@twitter</td>
              <td>
                  <Button variant="info" size="sm"><FontAwesomeIcon icon="info"/></Button>&nbsp;
                  <Link to={`/users/1`}><Button variant="warning" size="sm"><FontAwesomeIcon icon="pencil"/></Button>&nbsp;</Link>
                  <Button variant="dark" size="sm"><FontAwesomeIcon icon="ban"/></Button>&nbsp;
                  <Button variant="danger" size="sm"><FontAwesomeIcon icon="trash"/></Button>&nbsp;
              </td>
            </tr>
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  }


  render(){
    return <Container fluid>
      <Row>
        <Col>
          <Breadcrumb>
            <Breadcrumb.Item href="/public"><FontAwesomeIcon icon="home" />&nbsp; Home</Breadcrumb.Item>
            <Breadcrumb.Item href="/users"><FontAwesomeIcon icon="user" />&nbsp; Gebruikers <FontAwesomeIcon icon="star" /></Breadcrumb.Item>
            <Breadcrumb.Item active>Overzicht</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row className={'mb-2'} id="html">
        <Col>{this.search()}</Col>
      </Row>
      <Row>
        <Col sm={6} md={6}>{this.table()}</Col>
        <Col sm={6} md={6}>{this.table()}</Col>
      </Row>
      <Row>
        <Col sm={12} md={6}>{this.table()}</Col>
        <Col sm={12} md={6}>{this.table()}</Col>
      </Row>
    </Container>

  }

}


export default SplitView
