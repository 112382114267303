
import React from 'react';
import { Alert, Breadcrumb, Button, Card, Col, Form, Row } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import { ObjectSchema } from 'yup';
import { Appointment, Group, GroupRoles, User, UserGroup, UserGroupRole, UserGroupRoles, UserRoles, UserTypes } from '../../inc/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import APIClient from '../../inc/APIClient';
import { capitalize, dayName, getWeekDates, minutesToTimeStr, withRouterParams } from '../../inc/helpers';
import { Link } from 'react-router-dom';
import { CrudList } from '../../global/components/CrudList';

let api = new APIClient()

interface Params { id: string }

interface Props {
    params?: Params;
}

interface State extends User {

    errors?: string[];
    messages?: string[];

    id?: number;
    email: string;
    password: string;

    groups: UserGroup[]; // user group relations
    groupsData: any[]; // groups data from api
    groupSelect: number; // selected id
    groupRoleSelect: UserGroupRole; // selected role

    appointments: any[]; // user appointments
    appointmentsData: any[]; // appointments data from api

    appointmentSelectedDay: any; // selected day
    appointmentSelectedStart: string; // selected day
    appointmentSelectedStop: string; // selected day

}

export class UserForm extends React.Component <Props, State> {
    schema: ObjectSchema<any>;
    testValues : any[]

    constructor(props: Props){
        super(props);

        this.testValues = [
          {col1: "jgijgr", col2: 2, col3: "test3", col4: "option1", col5: new Date()},
          {col1: "row2", col2: 22, col3: "test3", col4: "option2", col5: new Date()}
        ];

        this.state = { // these get uses as initial values for the form. (fetch from api in other cases)
            email: "",
            password: "",
            name: "",
            role: "guest",
            status: "active",
            type: "guest",

            groups: [],
            groupSelect: 1,
            groupRoleSelect: "client",
            groupsData: [],

            appointments: [],
            appointmentsData: [],
            appointmentSelectedDay: new Date().toISOString(),
            appointmentSelectedStart: "09:00",
            appointmentSelectedStop: "13:00"
        };

        this.schema = yup.object().shape({ // yup schema for form validation
          email: yup.string().email("Not a valid email").required("Email is required"),
          password: yup.string().required("Password is required").min(8, "Password must be at least 8 characters"),
          name: yup.string().min(1, "Name is required").required("Name is required"),
          type: yup.string().required("Access level is required").oneOf(UserTypes, "Access level is required is required"),
          role: yup.string().required("Role is required").oneOf(UserRoles, "Role is required is required"),
          groups: yup.array().min(1, "At least 1 relationship must exist")
        });


        console.log(this.schema)

        this.onSubmit = this.onSubmit.bind(this);
        this.groupAdd = this.groupAdd.bind(this);
        this.groupDelete = this.groupDelete.bind(this);
        this.appointmentAdd = this.appointmentAdd.bind(this);
        this.appointmentDelete = this.appointmentDelete.bind(this);
        this.fetchUser = this.fetchUser.bind(this);
    }

    async onSubmit (values: Partial<State>, _form: any) {
        console.log(this.testValues)

        let user = { ...values, groups: this.state.groups }

        if (user.password && /^\*+$/g.test(user.password))
          user.password = ""

        if(user.id && user.id > 0) {
            api.UserUpdate(user).then((response)=>{ this.setState({ messages: response.messages, errors: response.errors, ...response.data[0] }) }).catch((err) => this.setState({ errors: [err] }))

            // handle appointments. add all that are not in the database yet
            this.state.appointments.forEach((appointment: any) => {
              if (!appointment.id) {
                api.AppointmentCreate(appointment).then((res: any) => {
                  console.log(res);
                }).catch((err) => console.error(err))
              }
            })

            this.fetchUser(user.id)

        } else {
            api.UserCreate(user).then((response)=>{
              this.setState({ messages: response.messages, errors: response.errors, ...response.data[0] })

              if (response.data[0].id){
                this.state.appointments.forEach((appointment: any) => {
                  appointment.user_id = this.state.id
                  api.AppointmentCreate(appointment).then((res: any) => {
                    console.log(res);
                  }).catch((err) => console.error(err))
                })

                this.fetchUser(response.data[0].id)
              }

            }).catch((err) => this.setState({ errors: [err] }))

        }
        
    }

    fetchAppointments(user_id: number) {
      api.AppointmentGetUser(user_id).then((res: any) => {
        if(res.data && res.data.length > 0){
          res.data = res.data.filter((appointment: any) => { return appointment.group_id === api.activeGroup })
          this.setState({ appointments: res.data ? res.data : [] })
        }else{
          // for example no appointments found
          // this.setState({ errors: [...res.errors] })
        }
      }).catch((err) => this.setState({ errors: [err] }))
    }

    fetchUser(id: number) {
      api.UserGet(id).then((res: any) => {
        this.setState({ ...{...res.data[0], password: "************"} });
        this.fetchAppointments(id)
      }).catch((err) => this.setState({ errors: [err] }))
    }


    appointmentAdd(values:any) { // add appointment relation

      if(!values.appointmentSelectedDay || !values.appointmentSelectedStart || !values.appointmentSelectedStop) return;

      // start stop time hh:ss to minutes number
      let start = values.appointmentSelectedStart.split(':')
      let stop = values.appointmentSelectedStop.split(':')

      values.appointmentSelectedStart = parseInt(start[0]) * 60 + parseInt(start[1])
      values.appointmentSelectedStop = parseInt(stop[0]) * 60 + parseInt(stop[1])

      // pad minutes to 2 digits
      values.appointmentSelectedStart = values.appointmentSelectedStart.toString().padStart(2, '0')
      values.appointmentSelectedStop = values.appointmentSelectedStop.toString().padStart(2, '0')


      let appointment = { title: `Weekindeling (${this.state.id})`, group_id: api.activeGroup, start:values.appointmentSelectedStart, stop: values.appointmentSelectedStop, start_date: values.appointmentSelectedDay, user_id: this.state.id, type: "schedule", meta : {
        unit: "week",
        frequency:1
      }}

      console.log(appointment, this.state.appointments);

      this.setState({ appointments: [...this.state.appointments, appointment] })
    }

    appointmentDelete(index:number) { // delete appointment relation
      if (this.state.appointments[index].id) {
        api.AppointmentDelete(this.state.appointments[index].id).then((res: any) => {

          let appointments = this.state.appointments
          appointments.splice(index, 1)
          this.setState({ appointments: appointments })

        }).catch((err) => console.error(err))
      } else {
        let appointments = this.state.appointments
        appointments.splice(index, 1)
        this.setState({ appointments: appointments })
      }

    }

    groupAdd(values:any) { // add group relation
      console.log(values);
      let groupRelation = { group_id: values.groupSelect, role: values.groupRoleSelect, user_id: this.state.id ? this.state.id : undefined  }
      this.setState({ groups: [...this.state.groups, groupRelation] })
    }

    groupDelete(index:number) { // delete group relation
      let groups = this.state.groups
      groups.splice(index, 1)
      this.setState({ groups: groups })
    }


    componentDidMount(): void {
      // fetch all groups for select
      api.GroupGetAll().then((res: any) => this.setState({ groupsData: res.data })).catch((err) => console.error(err))

      // parse params
      if (this.props.params && this.props.params.id) {
        let id = parseInt(this.props.params.id);
        if (id > 0 && !isNaN(id)) {
          this.fetchUser(id)
        }
      }
    }

    render(){

        let groupName = (id: any) => {
          id = parseInt(id)
          return this.state.groupsData.find((group: Group) => group.id === id)
        }

        return <>

        <Row>
            <Col md={12}>
              <Breadcrumb>
                  <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                  <Breadcrumb.Item active><FontAwesomeIcon icon="users" />&nbsp; Gebruikers </Breadcrumb.Item>
                  { this.state.id  && <Breadcrumb.Item active><FontAwesomeIcon icon="pencil" />&nbsp; Bewerken</Breadcrumb.Item> }
                  { !this.state.id  && <Breadcrumb.Item active><FontAwesomeIcon icon="pencil" />&nbsp; Toevoegen</Breadcrumb.Item> }
              </Breadcrumb>
            </Col>
        </Row>
        <Card>
            <Card.Body>
                { this.state.errors && <Alert variant="danger">{this.state.errors}</Alert> }
                { this.state.messages && <Alert variant="success">{this.state.messages }</Alert> }
                {/* enable reinitialize allows reloading initial values when state updates */}
                <Formik enableReinitialize={true} validationSchema={this.schema} onSubmit={this.onSubmit} initialValues={this.state}>
                    {({
                        handleSubmit, handleChange, handleBlur, values, touched, isValid, errors
                    }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column xs="2">Email</Form.Label>
                                <Col xs="10">
                                    <Form.Control
                                        type="text"
                                        name="email"
                                        value={values.email}
                                        onChange={handleChange}
                                        onKeyUp={handleBlur}
                                        isInvalid={touched.email && !!errors.email}
                                        placeholder="Example@email.com"/>
                                    <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column xs="2">Password</Form.Label>
                                <Col xs="10">
                                    <Form.Control
                                        type="password"
                                        name="password"
                                        value={values.password}
                                        onChange={handleChange}
                                        onKeyUp={handleBlur}
                                        onFocus={(e)=>{e.currentTarget.setSelectionRange(0, -1)}}
                                        isInvalid={touched.password && !!errors.password}
                                        placeholder="$MART p@ssw0rd!"/>
                                    <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column xs="2">Name</Form.Label>
                                <Col xs="10">
                                    <Form.Control
                                        type="text"
                                        name='name'
                                        value={values.name}
                                        onChange={handleChange}
                                        onKeyUp={handleBlur}
                                        isInvalid={touched.name && !!errors.name}
                                        placeholder="John Doe"/>
                                    <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                                </Col>
                            </Form.Group>

                            {/* <Form.Group as={Row} className="mb-2">
                                <Form.Label column xs="2">Role</Form.Label>
                                <Col xs="10">
                                    <Form.Select
                                        name="role" value={values.role}
                                        onChange={handleChange}
                                        onFocus={handleBlur}
                                        isInvalid={touched.role && !!errors.role}>
                                        <option value="guest">Guest</option>
                                        <option value="client">Client</option>
                                        <option value="employee">Employee</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">{errors.role}</Form.Control.Feedback>
                                </Col>
                            </Form.Group> */}

                            <Form.Group as={Row} className="mb-2 ">
                                <Form.Label column xs="2">Access Level <FontAwesomeIcon icon="skull"/></Form.Label>
                                <Col xs="10">
                                    <Form.Select
                                        name="type" value={values.type}
                                        onChange={handleChange}
                                        onFocus={handleBlur}
                                        isInvalid={touched.type && !!errors.type}>
                                        <option value="guest">Guest</option>
                                        <option value="user">User</option>
                                        <option value="moderator">Moderator</option>
                                        <option value="admin">Admin</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">{errors.type}</Form.Control.Feedback>
                                </Col>
                            </Form.Group>

                            <h6>Relaties</h6>
                            <Card style={{borderColor: touched.groups && typeof(errors.groups) == "string" ? "var(--bs-form-invalid-border-color)" : ""}}>
                              <Card.Body>
                              <Form.Group as={Row} className="mb-2">
                                <Col md={5}>
                                  <Form.Select name="groupSelect" value={values.groupSelect} onChange={handleChange}>
                                    {this.state.groupsData && this.state.groupsData.map((group: Group, index) => { return <option key={index} value={group.id}>{group.name}</option> })  }
                                  </Form.Select>
                                </Col>
                                <Col md={5}>
                                  <Form.Select name="groupRoleSelect" value={values.groupRoleSelect} onChange={handleChange}>
                                    { UserGroupRoles.map((role: string, index) => { return <option key={index} value={role}>{capitalize(role)} </option> })  }
                                  </Form.Select>
                                </Col>
                                <Col md={2}><Button variant="success" className='float-end' onClick={(e) => this.groupAdd(values) }><FontAwesomeIcon icon="plus" /></Button></Col>
                              </Form.Group>

                              <hr style={{color: touched.groups && typeof(errors.groups) == "string" ? "var(--bs-form-invalid-border-color)" : ""}}/>

                              <Form.Group as={Row} className="mb-2">
                                <Col md={12}>
                                  {  this.state.groups.map((relation: UserGroup, index: number) => { return <Row key={index}>
                                      <Col md={5}>{ groupName(relation.group_id)?.name }</Col>
                                      <Col md={5}>{relation.role}</Col>
                                      <Col md={2}><Button variant="danger" className='float-end'  onClick={() => this.groupDelete(index)}><FontAwesomeIcon icon="trash"/></Button></Col>
                                    </Row>
                                    })
                                  }
                                  {touched.groups && typeof(errors.groups) == "string" ? <div style={{display:"block"}} className="invalid-feedback">{errors.groups}</div> : ""}
                                </Col>
                              </Form.Group>
                              </Card.Body>
                            </Card>
                            <br/>

                            <h6>Weekindeling</h6>
                            <Card>
                              <Card.Body>
                              <Form.Group as={Row} className="mb-2">
                                <Col md={5}>
                                  <Form.Select name="appointmentSelectedDay" value={values.appointmentSelectedDay}
                                  onChange={handleChange}>
                                    { getWeekDates().map((date: Date,index) => { return <option key={index} value={date.toISOString().split('T')[0]}>{dayName(new Date(date.toISOString().split('T')[0]))}</option> }) }
                                  </Form.Select>
                                </Col>
                                <Col md={3}>
                                  <Form.Control
                                          type="time"
                                          name="appointmentSelectedStart"
                                          value={values.appointmentSelectedStart}
                                          onChange={handleChange}
                                          isInvalid={!!errors.appointmentSelectedStart}
                                          />
                                      <Form.Control.Feedback type="invalid">{errors.appointmentSelectedStart}</Form.Control.Feedback>
                                </Col>
                                <Col md={3}>
                                  <Form.Control
                                      type="time"
                                      name="appointmentSelectedStop"
                                      value={values.appointmentSelectedStop}
                                      onChange={handleChange}
                                      isInvalid={!!errors.appointmentSelectedStop}
                                      />
                                  <Form.Control.Feedback type="invalid">{errors.appointmentSelectedStop}</Form.Control.Feedback>
                                </Col>
                                <Col md={1}><Button variant="success" className='float-end' onClick={() => this.appointmentAdd(values)}><FontAwesomeIcon icon="plus" /></Button></Col>
                              </Form.Group>

                              <hr/>

                              { this.state.appointments && this.state.appointments.map((appointment: Appointment, index: number) => { return <Row key={index}>
                                  <Col md={5}>{ dayName(new Date(appointment.start_date)) }</Col>
                                  <Col md={3}>{`${minutesToTimeStr(parseInt(appointment.start))}` }</Col>
                                  <Col md={3}>{`${minutesToTimeStr(parseInt(appointment.stop))}` }</Col>
                                  <Col md={1}><Button variant="danger" className='float-end'  onClick={() => this.appointmentDelete(index)}><FontAwesomeIcon icon="trash"/></Button></Col>
                                </Row>
                                })
                              }
                              </Card.Body>
                            </Card>

                            <br/>
                            <Button type="submit" onClick={()=>{console.log(errors)}} variant={isValid ? "primary" : "danger"} ><FontAwesomeIcon icon="floppy-disk" /> &nbsp;Save</Button>
                        </Form>
                    )}
                </Formik>
                <CrudList tableDefinition={{name: "test", columns:[
                  {name:"col1", type:"string", restrictions: {required:true}, placeholder: "just a placeholder"},
                  {name:"col2", type:"number", restrictions: {required:true, min:{value: 0}, max:{value: 100}, typeMatch: "Bad number!"}},
                  {name:"col3", type:"string", restrictions: {required:{message:"Fill something in!"}, maxLength: {value: 20, message: "Too long!"}}},
                  {name:"col4", type:"select", selectOptions: ["option1", "option2", "option3"], restrictions: {required:{}}},
                  {name:"col5", type:"date", restrictions: {required: true}}
                  ]}}
                  values={this.testValues}/>
            </Card.Body>
        </Card>
      </>
    }
}

export default withRouterParams(UserForm);
