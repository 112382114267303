
import React from 'react';
import { Alert, Button, Card, Col, Form, Row } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import { ObjectSchema } from 'yup';

import APIClient from '../inc/APIClient';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

let api = new APIClient();

interface Parameters { }
interface Props { params?: Parameters; }

interface State {
    error?: string;
    message?: string;
    email: string;
    password: string;
}

export default class Login extends React.Component <Props, State> {
    schema: ObjectSchema<any>;

    constructor(props: Props){
        super(props);

        this.state = { // these get uses as initial values for the form. (fetch from api in other cases)
            email: "",
            password: ""
        };

        this.schema = yup.object().shape({ // yup schema for form validation
            email: yup.string().email("Not a valid email").required("Email is required"),
            password: yup.string().required("Password is required").min(8, "Password must be at least 8 characters")
        });

        this.onSubmit = this.onSubmit.bind(this);
        this.alreadyLoggedIn = this.alreadyLoggedIn.bind(this);
    }

    componentDidMount(): void {
        this.alreadyLoggedIn();
    }

    async alreadyLoggedIn() {
        let token = localStorage.getItem("token");
        if(token) {
            await api.UserCurrent().then((res) => {
                this.setState({ message: "Already logged in" });
                window.location.href = process.env.REACT_APP_URL + "/"
            }).catch((err) => {
                localStorage.removeItem("token");
            });
        }
    }

    async onSubmit (values: Partial<State>, _form: any) {

        if(!values.email || !values.password) return; // form is not valid

        let data = {
            email: values.email,
            password: values.password
        }

        await api.UserLogin(data).then((res) => {

            if(res.data && res.data.token) {

                let token = res.data.token;
                localStorage.setItem("token", token);
                this.setState({ message: "Logged in" });
                window.location.href = process.env.REACT_APP_URL + "/"

            }else{
                this.setState({ error: "Invalid credentials" });
            }

        }).catch((err) => {

            if(err.response.status === 401) {
                this.setState({ error: "Invalid credentials" });
            } else {
                this.setState({ error: "API may be offline" });
            }

        });


    }

    render(){

        return <Card className='login-form'>
            <Card.Body>

                <div className='login-heading'>
                  <strong>Inloggen</strong>
                  <span className='float-end'>Of <a href='/register'>maak account aan</a></span>
                </div>

                <Button variant="primary"><FontAwesomeIcon icon={['fab', 'google']} /> Inloggen met Google</Button><br/>
                <Button variant="secondary"><FontAwesomeIcon icon={['fab', 'github']} /> Inloggen met Github</Button><br/>
                <Button variant="dark"><FontAwesomeIcon icon={['fab', 'apple']} /> Inloggen met Apple</Button>

                <hr/>

                { this.state.error && <Alert variant="danger">{this.state.error}</Alert> }
                { this.state.message && <Alert variant="success">{this.state.message}</Alert> }
                <Formik validationSchema={this.schema} onSubmit={this.onSubmit} initialValues={this.state}>
                    {({
                        handleSubmit, handleChange, handleBlur, values, touched, isValid, errors
                    }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Form.Group as="div">
                                <Form.Control type="text" name="email" value={values.email} onChange={handleChange} isInvalid={!!errors.email} />
                                <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as="div">
                                <Form.Control type="password" name="password" value={values.password} onChange={handleChange} isInvalid={!!errors.password} />
                                <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                            </Form.Group>

                            <Row>
                                <Col>
                                    <Form.Check type="checkbox" label="Onthoud mij" />
                                </Col>
                                <Col>
                                  <Button type="submit" disabled={!isValid} className='float-end login-button' ><FontAwesomeIcon icon={["fas", "right-to-bracket"]} /> Login</Button>
                                </Col>
                            </Row>

                            <a href='/forgot-password'>Inloggegevens vergeten?</a>

                        </Form>
                    )}
                </Formik>

            </Card.Body>
        </Card>


    }
}
