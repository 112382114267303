
import React from 'react';
import {  Alert, Button, Card,  Table, Tooltip } from 'react-bootstrap';

import APIClient from '../../inc/APIClient';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Appointment } from '../../inc/types';
import { Link } from 'react-router-dom';
import { dayName, minutesToTimeStr, withRouterParams } from '../../inc/helpers';


let api = new APIClient();

interface Parameters { userType : string; }
interface Props { params?: Parameters; }

interface State {
    users?: any[];
    user?: any;
}

export class AppointmentOverview extends React.Component <Props, State> {

    constructor(props: Props){
        super(props);

        this.state = {};
        this.loadData = this.loadData.bind(this);
    }

    async loadData(){

      let group_id = api.activeGroup;
      let type = this.props.params?.userType ? this.props.params?.userType : '';


      api.UserGetAll().then(async (res) => {
        res.data = res.data.filter((user:any) => {
          let data = user.groups.find((relation:any) => { return relation.group_id == group_id; })
          if(type){ return (data && data.role == type); }
          return (data) ? true : false;
        });

        let users = res.data;

        let dateStart = new Date();
        dateStart.setDate(dateStart.getDate() - dateStart.getDay());
        dateStart.setHours(0,0,0,0);

        let dateStop = new Date();
        dateStop.setDate(dateStop.getDate() + (6 - dateStop.getDay()));
        dateStop.setHours(23,59,59,999);

        for (let user of users){
          let appointments = await api.AppointmentSearch( dateStart.toISOString(), dateStop.toISOString(), [user.id] );
          user.appointments = appointments.data;
        }

        this.setState({ users: res.data });
      }).catch((err) => {
          console.log(err);
      });


    }



    componentDidMount(): void {
      this.loadData();
    }

    componentDidUpdate(prevProps: Readonly<Props>, _prevState: Readonly<State>, _snapshot?: any): void {
      if (this.props.params?.userType !== prevProps.params?.userType) {
        this.loadData();
      }
    }

    render(){

        let row = (user: any) => {

            let days = ["Maandag", "Dinsdag", "Woensdag", "Donderdag", "Vrijdag"];

            return <tr key={user.id}>
                <td>{user.name}</td>
                <td>{user.groups.find((g:any) => { return g.group_id == api.activeGroup })?.role}</td>
                {days.map((day, index) => {

                  return <td>{
                    user.appointments?.filter((a:any) => { return dayName(new Date(a.start_date)) == day })
                    .map((a:any) => {  return <div key={a.id}><FontAwesomeIcon icon="clock" />&nbsp; {minutesToTimeStr(a.start)} - {minutesToTimeStr(a.stop)}
                    { a.attendances && a.attendances.length > 0 && <div>
                      <FontAwesomeIcon icon="check" /> {a.attendances[0].status}
                    </div> }

                    </div> })
                  }</td>
                })}



            </tr>
        }

        return <>

        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Id</th>
              <th>Type</th>
              <th>Maandag</th>
              <th>Dinsdag</th>
              <th>Woensdag</th>
              <th>Donderdag</th>
              <th>Vrijdag</th>
            </tr>
          </thead>
          <tbody>
            {this.state.users?.map(row)}
          </tbody>
        </Table>

        </>


    }
}


export default withRouterParams(AppointmentOverview);
