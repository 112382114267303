
import React from 'react';
import {  Alert, Button, Card,  Table, Tooltip } from 'react-bootstrap';

import APIClient from '../../inc/APIClient';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Appointment } from '../../inc/types';
import { Link } from 'react-router-dom';
import { dayName, withRouterParams } from '../../inc/helpers';


let api = new APIClient();

interface Parameters { AppointmentType : string; }
interface Props { params?: Parameters; }

interface State {
    Appointments?: Array<Appointment>;
    user?: any;
}

export class AppointmentOverview extends React.Component <Props, State> {

    constructor(props: Props){
        super(props);

        this.state = {};
        this.loadAppointments = this.loadAppointments.bind(this);
    }

    async loadAppointments(){
      api.AppointmentGetGroup(api.activeGroup).then((res) => {
        if (this.props.params?.AppointmentType){
          // filter Appointments that have group relation 1 and then by type compared to params
          res.data = res.data.filter((Appointment:any) => {
            let data = Appointment.groups.find((relation:any) => { return relation.group_id == api.activeGroup; })
            if (data && data.role == this.props.params?.AppointmentType){
              return true;
            }
          });
        }

        this.setState({ Appointments: res.data });
      }
      ).catch((err) => {
          console.log(err);
      });
    }



    componentDidMount(): void {
      this.loadAppointments();
    }

    componentDidUpdate(prevProps: Readonly<Props>, _prevState: Readonly<State>, _snapshot?: any): void {
      if (this.props.params?.AppointmentType !== prevProps.params?.AppointmentType) {
        this.loadAppointments();
      }
    }

    render(){

        let row = (Appointment: Appointment) => {
            return <tr key={Appointment.id}>
                <td>{Appointment.user_id}</td>
                <td>{Appointment.type}</td>
                <td>{Appointment.title}</td>
                <td>{dayName(new Date(Appointment.start_date))}</td>

                <td>
                    <Link className='me-1' to="/appointments"><Button variant="info" size="sm"><FontAwesomeIcon icon="info"/></Button></Link>
                </td>
            </tr>
        }

        return <Card>
            <Card.Header>
              Appointments overview
            </Card.Header>
            <Card.Body>
                <Alert variant='danger'>
                  <Alert.Heading><FontAwesomeIcon icon="skull" /> Help!!!</Alert.Heading>
                  Lots of the functionality here has to be reachable from the user module / forms directly.
                </Alert>
                <Table striped bordered hover >
                    <thead>
                        <tr>
                            <th>User_ID</th>
                            <th>Type</th>
                            <th>Title</th>
                            <th>Dag</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.Appointments && this.state.Appointments.map((Appointment) => {
                            return row(Appointment);
                        })}
                        {this.state.Appointments && this.state.Appointments.length === 0 && <tr><td colSpan={5}>No Appointments found</td></tr>}
                    </tbody>
                </Table>

            </Card.Body>
        </Card>


    }
}


export default withRouterParams(AppointmentOverview);
