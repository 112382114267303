
import React from 'react';
import { Breadcrumb, Card, Col, Container, ListGroup, Row } from 'react-bootstrap';
import APIClient from '../inc/APIClient';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Menutabs from '../layout/menutabs';

let api = new APIClient();

interface Props {}
interface State {}

class Home extends React.Component <Props, State> {

  render(){
    return <>
    <Container fluid>

      <Row className='striped'>
        <Col>
          <Breadcrumb>
            <Breadcrumb.Item href="/">Homepage</Breadcrumb.Item>
            <Breadcrumb.Item href="/">Homepage</Breadcrumb.Item>
            <Breadcrumb.Item href="/">Homepage</Breadcrumb.Item>
            <Breadcrumb.Item href="/">Homepage</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>

      <Row className='striped'>
        <Col>a</Col>
      </Row>

      <Row className='striped'>
        <Col>b</Col>
      </Row>

      <br/><hr/>

      <Card>
          <Card.Header><FontAwesomeIcon icon="house" />&nbsp; Home</Card.Header>
          <Card.Body>

            <h4>TODO</h4>

            <p>
              <ListGroup>

                <ListGroup.Item variant='info'>Active class / active page in menu tab.</ListGroup.Item>
                <ListGroup.Item variant='info'>Gebruiker toevoegen knop weg op add form. </ListGroup.Item>
                <ListGroup.Item variant='info'>Afspraken knop. Onduidelijke functionaliteit.</ListGroup.Item>
                <ListGroup.Item variant='danger'>Wisselen van actieve groep werkt tijdelijk niet door werkzaamheden menu tabs.</ListGroup.Item>
              </ListGroup>
            </p>
          </Card.Body>
      </Card>

    </Container>





    </>

  }

}


export default Home
