

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { Tab, Row, Col, Nav, Alert, Tooltip, OverlayTrigger } from 'react-bootstrap';
import APIClient from '../inc/APIClient';
import { Link } from 'react-router-dom';

let api = new APIClient();

interface Props { show: boolean; cb: any; }
interface State {}

class Menutabs extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {};

    this.setTab = this.setTab.bind(this);
    this.tabChanged = this.tabChanged.bind(this);
    let self = this
    window.addEventListener('storage', function(e) { self.tabChanged(e) });
  }


  // usage: <Nav.Link eventKey="users" onClick={this.setTab}> ... </Nav.Link>
  setTab(event: any) {

    let tab = event.target.getAttribute('data-rr-ui-event-key') // get eventkey from onClick event target.
    if(!tab){ tab = event.target.parentElement.getAttribute('data-rr-ui-event-key') } // if not found, try parent
    if(!tab){ tab = event.target.parentElement.parentElement.getAttribute('data-rr-ui-event-key')  } // if not found, try grandparent
    if(!tab){ tab = event.target.children[0].getAttribute('data-rr-ui-event-key') } // if not found, try child

    if(tab == null) return; // we tried..

    console.log('setTab: ' + tab)
    sessionStorage.setItem('activeTab', tab) // why doesnt this trigger a storage event?
    this.forceUpdate()
  }

  tabChanged(event?: any) {
    console.log("ello ?", sessionStorage.getItem('activeTab'));

    this.forceUpdate()
  }

  render() {



    let style = <style></style>;

    if(this.props.show == false){
      style = <style>{`
        .tab-content{  display: none;  }
        .tabs{  display: none;  }
        `}
      </style>
    }

    let callbackToggle = () => {
      if(!this.props.show) {
        this.props.cb()
      }
    }

    // get activeTab from session
    let activeTab = sessionStorage.getItem('activeTab') || 'default';

    console.log('activeTab: ' + activeTab)
    return ( <>
        {style}
        <Tab.Container id="tab-container" defaultActiveKey="default" activeKey={activeTab}>
            <div className='icons'>
              <Nav variant="pills" className="flex-column">
                <Nav.Item onClick={callbackToggle}>
                <OverlayTrigger delay={{show:1000, hide:0 }} placement="right" overlay={<Tooltip>Home</Tooltip>}>
                  <Nav.Link eventKey="default" onClick={this.setTab}><FontAwesomeIcon icon="home" style={{marginLeft:"-2px"}}/></Nav.Link>
                </OverlayTrigger>
                </Nav.Item>
                <Nav.Item onClick={callbackToggle}>
                <OverlayTrigger delay={{show:1000, hide:0 }} placement="right" overlay={<Tooltip>Users</Tooltip>}>
                  <Nav.Link eventKey="users" onClick={this.setTab}><FontAwesomeIcon icon="user"/></Nav.Link>
                </OverlayTrigger>
                </Nav.Item>
                <Nav.Item onClick={callbackToggle}>
                <OverlayTrigger delay={{show:1000, hide:0 }} placement="right" overlay={<Tooltip>Schedules</Tooltip>}>
                  <Nav.Link eventKey="appointments" onClick={this.setTab}><FontAwesomeIcon icon="calendar-days"/></Nav.Link>
                </OverlayTrigger>
                </Nav.Item>
                <div className='separator' style={{marginTop:"14px", marginBottom:"14px"}}></div>
                <Nav.Item onClick={callbackToggle}>
                <OverlayTrigger delay={{show:1000, hide:0 }} placement="right" overlay={<Tooltip>Test      </Tooltip>}>
                  <Nav.Link eventKey="fire" onClick={this.setTab}><FontAwesomeIcon icon="dumpster-fire"/></Nav.Link>
                </OverlayTrigger>
                </Nav.Item>
                <Nav.Item onClick={api.logout}>
                <OverlayTrigger delay={{show:1000, hide:0 }} placement="right" overlay={<Tooltip>Logout</Tooltip>}>
                  <Nav.Link><FontAwesomeIcon icon="door-open"/></Nav.Link>
                </OverlayTrigger>
                </Nav.Item>
              </Nav>
            </div>
            <div className='tabs'>
              <Tab.Content>
                <Tab.Pane eventKey="default" className='tab-home'>
                  <Link to="/">Homepage</Link>
                  <Link to="/users">Gebruikers</Link>
                  <Link to="/attendance">Agenda</Link>
                  <div className='separator'>Modules</div>
                  <Link to="/test3">Tests</Link>
                  <Link to="/">Logout</Link>


                </Tab.Pane>
                <Tab.Pane eventKey="users">


                  <strong>Gebruikers</strong>
                  <Link to="/users">Users</Link>
                  <Link to="/users/client">Clienten</Link>
                  <Link to="/users/employee">Werknemers</Link>
                  <Link to="/users/intern">Stagairs</Link>
                  <Link to="/users/volunteer">Vrijwilligers</Link>
                  {/* <Link to="/users/add"><FontAwesomeIcon icon="plus" />&nbsp; Gebruiker toevoegen</Link> */}

                  <strong>Iets anders</strong>
                  <Link to="/users/overview">Overzicht</Link>
                  <Link to="/users/add">Gebruiker toevoegen</Link>
                </Tab.Pane>
                <Tab.Pane eventKey="appointments">
                  <Link to="/appointments">Afspraken</Link>
                  <Link to="/appointments/for/1">Afspraak voor admin</Link>
                  <Link to="/appointments/user/1">Overzicht voor admin</Link>
                </Tab.Pane>
                <Tab.Pane eventKey="attendance">

                  <Link to="/attendance/week">Weekoverzicht</Link>
                  <hr/>
                  <Link to="/attendance/week/client">Weekoverzicht clienten</Link>
                  <Link to="/attendance/week/employee">Weekoverzicht werknemers</Link>
                  <Link to="/attendance/week/intern">Weekoverzicht stagairs</Link>
                  <Link to="/attendance/week/volunteer">Weekoverzicht vrijwilligers</Link>
                </Tab.Pane>
                <Tab.Pane eventKey="fire">
                  <Alert variant='info'><FontAwesomeIcon icon="skull" size='4x'/> <FontAwesomeIcon icon="skull" size='4x' className='float-end'/></Alert>

                  <Link to="/testjunk">TestJ 1</Link>
                  <Link to="/testjunk2">TestJ 2</Link>
                  <Link to="/test">Test 3</Link>
                </Tab.Pane>
              </Tab.Content>
            </div>
        </Tab.Container>
      </>
    );
  }
}


export default Menutabs;
